<div class="wrap-dashboard">
    
  
    <div class="wrap-center-body">
        <div class="grid mb-4" >
            <div class="col-12 lg:col sm:col-12 sm:mb-2">
                <h1 class="h4 m-0">Dashboard Overview</h1>
            </div>
            <div class="col-12 lg:col sm:col-12 flex xl:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start lg:align-items-center  md:gap-3 gap-2 lg:flex-row flex-column">
              @if(reviewer){
                <div *ngIf="showAvailableButton && reviewer.role !== 'CANDIDATE'" class="reduce-bottom-space margin-top-minus-6">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="d-flex">
                              <div class="flex flex-row padding-right-ten top-ten">
                                  <!-- Tooltip when the user is not marked available -->
                                  @if(!reviewer.isMarkedAvailable){
                                    <span class="info-markavailable text-primary-700 margin-right flex material-symbols-outlined cursor-pointer fs-small" pTooltip="Let Staftr Staffing Partners know that you are actively available and interested in receiving offers for upcoming jobs" tooltipPosition="bottom">
                                      info
                                    </span>
                                  }

                                  @if(reviewer.isMarkedAvailable){
                                    <span class="info-markavailable text-primary-700 margin-right flex material-symbols-outlined cursor-pointer fs-small"  pTooltip="You have already conveyed your availability to Staftr Staffing Partners. Make sure to check back in at the beginning of each week to mark yourself available" tooltipPosition="bottom">
                                      info
                                    </span>
                                  }
                                  <div  class="flex md:gap-3 gap-2">
                                    <p-button [label]="!reviewer.isMarkedAvailable ? 'I Am Available' : 'Available'" [disabled]="reviewer.isMarkedAvailable" (onClick)='markAvailable()'[rounded]="true" styleClass="white-space-nowrap vibrant-pink-background" />
                                    <p-divider layout="vertical" styleClass="py-2" />
                                  </div>
                              </div>                          
                          </div>
                      </div>
                  </div>
              
                  <!-- Display last updated date -->
                  <span class="info-markavailable last-updated fs-small" *ngIf="reviewer.markedAvailableAt">
                    <strong>Last Updated:</strong> <br>{{ reviewer.markedAvailableAt | date:'MMMM, dd yyyy' }} {{ reviewer.markedAvailableAt | date:'hh:mm a' }}
                  </span>
                </div>
              }
              
                @if(getCurrentPositions().length){
                  <div class="flex md:gap-3 gap-2">
                    <span class="h4">{{dailyDuration?.hours}}:{{dailyDuration?.minutes}}:{{dailyDuration?.seconds}}</span>  
                    @if(this.roles){
                      @if(!isClockedIn){
                        <p-button (onClick)="showRolesModal($event)" label="START TIME" [rounded]="true" styleClass="teal white-space-nowrap" />
                      }@else{
                        <p-button (onClick)="clockOut()" label="END TIME" [rounded]="true" severity="success" styleClass="red-background white-space-nowrap" />
                      }
                    }
                 
                      <p-button label="GO TO TIME KEEPING" (click)="goToTimekeeping()" [rounded]="true" class="white-space-nowrap" />
                  </div>
                }
            </div>
        </div>
        <div class="grid">
            <div class="col-12 lg:col-12 xl:col-8 sm:col-12">
              @if(reviewer){
                <p-panel styleClass="mb-4">
                  <ng-template pTemplate="content">
                      <div class="grid grid-nogutter px-2">
                          <div class="col-12 md:col-6 flex flex-column">
                              <h6 class="mt-1 mb-2">{{reviewer.firstName}} {{reviewer.lastName}}</h6>
                              <span *ngIf="reviewer.profile && reviewer.profile.contact" class="text-sm font-medium">{{reviewer.profile.contact.street}}, {{reviewer.profile.contact.city}}, {{reviewer.profile.contact.state}}</span>
                          </div>
                          <div class="col-12 md:col-6 flex flex-column">
                              <div class="flex flex-column gap-2 mb-2">
                                  <p-progressBar [style]="{ height: '4px' }" [value]="profileStrengthValue" />
                                  <span class="text-xs">Your profile is {{profileStrengthValue}}% completed.</span>
                              </div>
                              <p-button [routerLink]="'/reviewer/profile/main'" [link]="true" styleClass="p-0 ">EDIT PROFILE</p-button>
                          </div>
                      </div>
                  </ng-template>
                </p-panel>
              }
                <p-panel>
                  <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event,activeTabIndex)">
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                          <span class="ml-3 mr-3">TO DO LIST</span>  <p-badge class="flex right" [value]="toDoList.length" severity="primary"></p-badge>
                        </ng-template>
                          <div>
                              @if(getCurrentPositions().length > 0) {
                              <p-dropdown [(ngModel)]="selectedCurrentPosition" optionLabel="name" [options]="getCurrentPositions()"
                                placeholder="Select Role" (onChange)="getTsheetList(getCurrentWeek(),selectedCurrentPosition)" />
                              }
                              <div *ngIf="toDoList.length === 0" class="pt-4 pl-3"> You're all caught up! </div>
                              @if(toDoList.length){
                                <p-dataView>
                                  <ng-template pTemplate="empty">
                                      <div class="grid grid-nogutter">
                                        @for(list of toDoList; track list){
                                          <div class="col-12">
                                              <div class="flex flex-column sm:flex-row sm:align-items-center py-3 gap-3  border-bottom-1 surface-border">
                                                  <div class="flex flex-column md:flex-row justify-content-between md:align-items-start flex-1 gap-4">
                                                      <div class="flex flex-row md:flex-column justify-content-between align-items-start gap-2">
                                                          <div>
                                                              <div class="text-sm text-primary font-medium mt-1">{{list.header}}</div>
                                                              <p class="text-sm m-0">{{list.body}}
                                                              </p>
                                                          </div>
                                                          @if (list.actions.length) {
                                                            <div class="flex flex-row justify-content-between align-items-start gap-3">
                                                              @for (button of list.actions; track $index) {
                                                                <p-button (click)="button.link()" [link]="true" styleClass="p-0">{{button.label}}</p-button>
                                                              }
                                                            </div>
                                                          }
                                                      </div>
                                                      @if (list.createdAt) {
                                                        <div class="flex md:align-items-end gap-1 text-xs text-black-alpha-60 flex-nowrap white-space-nowrap">
                                                          <span class="font-semibold">{{list.createdAt | date:'hh:mm a' }}</span>
                                                          <span> | </span>
                                                          <span>{{list.createdAt | date:'MMMM, dd' }}</span>
                                                        </div>
                                                      }
                                                  </div>
                                              </div>
                                          </div>
                                        }
                                      </div>
                                  </ng-template>
                                </p-dataView>
                              }
                          </div>
                    </p-tabPanel>
                    @if(getCurrentPositions().length > 0) {
                      <p-tabPanel>
                          <ng-template pTemplate="header">
                            <span class="ml-3 mr-3">CURRENT JOBS</span>
                          </ng-template>
                          <div class="flex flex-column gap-4">
                              <p-dropdown [(ngModel)]="selectedCurrentPosition"  optionLabel="name" [options]="getCurrentPositions()"
                                  placeholder="Select Role" (onChange)="getTsheetList(getCurrentWeek(),selectedCurrentPosition)" />
                              @if(selectedCurrentPosition){
                                  <p-button [routerLink]="'/reviewer/messages'" [rounded]="true" size="small">MESSAGES</p-button>
                                <div class="flex flex-column gap-3">
                                  
                                  <div class="grid">
                                      <div class="col-6 lg:col flex flex-column">
                                          <span class="text-xs text-primary">Rate</span>
                                          <span class="text-sm font-medium">${{selectedCurrentPosition?.role?.hourlyRate}}/hr</span>
                                      </div>
                                      <div class="col-6 lg:col flex flex-column">
                                          <span class="text-xs text-primary">Duration</span>
                                          <span class="text-sm font-medium">{{selectedCurrentPosition?.role?.workSchedule?.duration}}</span>
                                      </div>
                                      <div class="col-6 lg:col flex flex-column">
                                          <span class="text-xs text-primary">Hours</span>
                                          <span class="text-sm font-medium">{{selectedCurrentPosition?.role?.workSchedule?.workHours}}</span>
                                      </div>
                                      <div class="col-6 lg:col flex flex-column">
                                          <span class="text-xs text-primary">Address</span>
                                          <span class="text-sm font-medium">{{selectedCurrentPosition?.role?.reviewCenterLocation?.isRemote ?
                                              'Remote':
                                              (selectedCurrentPosition?.role?.reviewCenterLocation?.reviewLocation?.location?.city??'')+', '+(selectedCurrentPosition?.role?.reviewCenterLocation?.reviewLocation?.location?.state??'')}}</span>
                                      </div>
                                      
                                  </div>
                                  <div class="grid">
                                    <div class="col-6 lg:col flex flex-column">
                                      <span class="text-xs text-primary">Qualification</span>
                                      @if(selectedCurrentPosition.role?.degrees?.length){
                                        <span class="text-xs">Degrees</span>
                                        @for(degree of selectedCurrentPosition.role.degrees; track degree){
                                          <span class="text-sm font-medium">{{degree.major}}</span>
                                        }
                                      }
                                      @if(selectedCurrentPosition.role?.criteriaTemplate?.length){
                                        <span class="text-xs">Skills and Experience</span>
                                        @for(skill of selectedCurrentPosition.role.criteriaTemplate; track skill){
                                            <span class="text-sm font-medium">{{skill.name}}</span>
                                          }
                                      }
                                      @if(selectedCurrentPosition.role?.credentialTemplate?.length){
                                        <span class="text-xs">Credentials</span>
                                        @for(cred of selectedCurrentPosition.role.credentialTemplate; track cred){
                                            <span class="text-sm font-medium">{{cred.name}}</span>
                                        }
                                      }
                                  </div>
                                      <div class="col-6 lg:col flex flex-column">
                                          <span class="text-xs text-primary">Start Date</span>
                                          <span class="text-sm font-medium">{{selectedCurrentPosition.role.startAt | date:'MMM dd, YYYY'}} </span>
                                          <span class="text-sm font-medium">{{selectedCurrentPosition.role.startAt | date:'hh:mm a'}} - {{selectedCurrentPosition.role.timezone}}</span>
                                      </div>
                                      <div class="col-6 lg:col flex flex-column">
                                          <span class="text-xs text-primary">Schedule</span>
                                          @if(selectedCurrentPosition?.role?.workSchedule?.schedule?.weekDays?.willWork){
                                              <span class="text-sm font-medium">M-F (
                                                {{
                                                  selectedCurrentPosition?.role?.workSchedule?.schedule?.weekDays?.time?.start?.hour.toString().padStart(2, '0')
                                                }}:{{
                                                  selectedCurrentPosition?.role?.workSchedule?.schedule?.weekDays?.time?.start?.min.toString().padStart(2, '0')
                                                }}
                                                {{
                                                  selectedCurrentPosition?.role?.workSchedule?.schedule?.weekDays?.time?.start?.meridiem
                                                }}
                                                --
                                                {{ selectedCurrentPosition?.role?.workSchedule?.schedule?.weekDays?.time?.end?.hour.toString().padStart(2, '0') }}:{{
                                                  selectedCurrentPosition?.role?.workSchedule?.schedule?.weekDays?.time?.end?.min.toString().padStart(2, '0')
                                                }}
                                                {{ selectedCurrentPosition?.role?.workSchedule?.schedule?.weekDays?.time?.end?.meridiem }}
                                              )</span>
                                          }
                                          @if(selectedCurrentPosition?.role.workSchedule.schedule.saturday.willWork){
                                              <span class="text-sm font-medium">SAT (
                                                {{
                                                  selectedCurrentPosition.role.workSchedule.schedule.saturday.time.start.hour.toString().padStart(2, '0')
                                                }}:{{
                                                  selectedCurrentPosition.role.workSchedule.schedule.saturday.time.start.min.toString().padStart(2, '0')
                                                }}
                                                {{
                                                  selectedCurrentPosition.role.workSchedule.schedule.saturday.time.start.meridiem
                                                }}
                                                --
                                                {{ selectedCurrentPosition.role.workSchedule.schedule.saturday.time.end.hour.toString().padStart(2, '0') }}:{{
                                                  selectedCurrentPosition.role.workSchedule.schedule.saturday.time.end.min.toString().padStart(2, '0')
                                                }}
                                                {{ selectedCurrentPosition.role.workSchedule.schedule.saturday.time.end.meridiem }}
                                              )</span>
                                          }
                                          @if(selectedCurrentPosition?.role.workSchedule.schedule.sunday.willWork){
                                              <span class="text-sm font-medium">SUN (
                                                {{
                                                  selectedCurrentPosition.role.workSchedule.schedule.sunday.time.start.hour.toString().padStart(2, '0')
                                                }}:{{
                                                  selectedCurrentPosition.role.workSchedule.schedule.sunday.time.start.min.toString().padStart(2, '0')
                                                }}
                                                {{
                                                  selectedCurrentPosition.role.workSchedule.schedule.sunday.time.start.meridiem
                                                }}
                                                --
                                                {{ selectedCurrentPosition.role.workSchedule.schedule.sunday.time.end.hour.toString().padStart(2, '0') }}:{{
                                                  selectedCurrentPosition.role.workSchedule.schedule.sunday.time.end.min.toString().padStart(2, '0')
                                                }}
                                                {{ selectedCurrentPosition.role.workSchedule.schedule.sunday.time.end.meridiem }}
                                              )</span>
                                          }
                                      </div>
                                      <div class="col-6 lg:col flex flex-column">
                                          <span class="text-xs text-primary">POC</span>
                                          <span class="text-sm font-medium">{{selectedCurrentPosition.role.reviewCenterLocation.pointOfContact}}</span>
                                          <span class="text-sm font-medium">{{selectedCurrentPosition.role.reviewCenterLocation.email}}</span>
                                      </div>
                                  </div>
                              </div>
                              <div class="flex flex-column gap-3">
                                  <span class="text-sm font-medium">Candidate Hours</span>
                                  <div *ngIf="selectedCurrentPosition.project.tsheet.isEnabled && enableTimeTrackerGraph" class="mp-tab-detail-container">
                                    <h3 class="cursor-pointer">Time Tracker</h3>
                                    <div class="mp-graph-section">
                                      <div class="flex flex-row gap-3">
                                        <h6>Daily:</h6>                                      
                                          <span *ngIf="dailyDuration"> {{ dailyDuration.hours }}:{{ dailyDuration.minutes }}:{{ dailyDuration.seconds }}</span>
                                        
                                          <h6>Last 2 Weeks:</h6>
                                          <span *ngIf="weekDuration">{{ weekDuration.hours }}:{{ weekDuration.minutes }}</span>
                                        
                                          <h6>Total: </h6>
                                          <span *ngIf="totalDuration">{{ totalDuration.hours }}:{{ totalDuration.minutes }}</span>
                                        
                                      </div>
                                      <div class="chart">
                                        <app-chart 
                                          chartHeight="600px"
                                          [datasets]="graphData" 
                                          [labels]="graphLabels" 
                                          >
                                        </app-chart>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div class="flex justify-content-between gap-2 md:flex-row flex-column-reverse">
                                  <p-button [rounded]="true" size="small" severity="danger" (click)="quitPosition(selectedCurrentPosition)">END ROLE</p-button>
                                  <div class="flex gap-2">
                                      <p-button [rounded]="true" size="small" (click)="onViewTimeSheet(selectedCurrentPosition)" styleClass="teal">TIME KEEPING</p-button>
                                      @if(documentsExist() || (this.selectedCurrentPosition.firm.isDocusignEnabled && this.selectedCurrentPosition.docusignEnabled)){
                                        <p-button (onClick)="openViewDocumentsModal()" [rounded]="true" [outlined]="true" size="small">VIEW DOCUMENTS</p-button>
                                      }
                                  </div>
                              </div>
                              }
                          </div>
                      </p-tabPanel>
                    }
                    @if(getFuturePositions().length){
                      <p-tabPanel>
                          <ng-template pTemplate="header">
                            <span class="ml-3 mr-3">FUTURE JOBS</span>
                          </ng-template>
                          <div class="flex flex-column gap-4">
                              <p-dropdown [(ngModel)]="selectedFuturePosition" optionLabel="name" [options]="getFuturePositions()"
                                  placeholder="Select Position" (onChange)="onChangeFuturePosition(getCurrentWeek(),selectedFuturePosition)" />
                                <p-button [routerLink]="'/reviewer/messages'" [rounded]="true" size="small">MESSAGES</p-button> 
                            @if(selectedFuturePosition){
                            <div class="flex flex-column gap-3">
                                  
                              <div class="grid">
                                  <div class="col-6 lg:col flex flex-column">
                                      <span class="text-xs text-primary">Rate</span>
                                      <span class="text-sm font-medium">${{selectedFuturePosition?.role?.hourlyRate}}/hr</span>
                                  </div>
                                 @if(selectedFuturePosition.role.overTime){
                                  <div class="col-6 lg:col flex flex-column">
                                    <span class="text-xs text-primary">Overtime</span>
                                    <span class="text-sm font-medium">${{selectedFuturePosition?.role?.overTime}}/hr</span>
                                  </div>
                                  }
                                  <div class="col-6 lg:col flex flex-column">
                                      <span class="text-xs text-primary">Duration</span>
                                      <span class="text-sm font-medium">{{selectedFuturePosition?.role?.workSchedule?.duration}}</span>
                                  </div>
                                  <div class="col-6 lg:col flex flex-column">
                                      <span class="text-xs text-primary">Hours</span>
                                      <span class="text-sm font-medium">{{selectedFuturePosition?.role?.workSchedule?.workHours}}</span>
                                  </div>
                                  <div class="col-6 lg:col flex flex-column">
                                      <span class="text-xs text-primary">Address</span>
                                      <span class="text-sm font-medium">{{selectedFuturePosition?.role?.reviewCenterLocation?.isRemote ?
                                          'Remote':
                                          selectedFuturePosition?.role?.reviewCenterLocation?.reviewLocation?.location?.city+', '+selectedFuturePosition?.role?.reviewCenterLocation?.reviewLocation?.location?.state}}</span>
                                  </div>
                                  
                              </div>
                              <div class="grid">
                                <div class="col-6 lg:col flex flex-column">
                                  <span class="text-xs text-primary">Qualification</span>
                                  @if(selectedFuturePosition.role?.degrees?.length){
                                    <span class="text-xs">Degrees</span>
                                    @for(degree of selectedFuturePosition.role.degrees; track degree){
                                      <span class="text-sm font-medium">{{degree.major}}</span>
                                    }
                                  }
                                  @if(selectedFuturePosition.role?.criteriaTemplate?.length){
                                    <span class="text-xs">Skills and Experience</span>
                                    @for(skill of selectedFuturePosition.role.criteriaTemplate; track skill){
                                        <span class="text-sm font-medium">{{skill.name}}</span>
                                      }
                                  }
                                  @if(selectedFuturePosition.role?.credentialTemplate?.length){
                                    <span class="text-xs">Credentials</span>
                                    @for(cred of selectedFuturePosition.role.credentialTemplate; track cred){
                                        <span class="text-sm font-medium">{{cred.name}}</span>
                                    }
                                  }
                              </div>
                                  <div class="col-6 lg:col flex flex-column">
                                      <span class="text-xs text-primary">Start Date</span>
                                      <span class="text-sm font-medium">{{selectedFuturePosition.role.startAt | date:'MMM dd, YYYY'}} </span>
                                      <span class="text-sm font-medium">{{selectedFuturePosition.role.startAt | date:'hh:mm a'}} - {{selectedFuturePosition.role.timezone}}</span>
                                  </div>
                                  <div class="col-6 lg:col flex flex-column">
                                      <span class="text-xs text-primary">Schedule</span>
                                      @if(selectedFuturePosition?.role?.workSchedule?.schedule?.weekDays?.willWork){
                                        <span class="text-sm font-medium">M-F (
                                          {{
                                            selectedFuturePosition?.role?.workSchedule?.schedule?.weekDays?.time?.start?.hour.toString().padStart(2, '0')
                                          }}:{{
                                            selectedFuturePosition?.role?.workSchedule?.schedule?.weekDays?.time?.start?.min.toString().padStart(2, '0')
                                          }}
                                          {{
                                            selectedFuturePosition?.role?.workSchedule?.schedule?.weekDays?.time?.start?.meridiem
                                          }}
                                          --
                                          {{ selectedFuturePosition?.role?.workSchedule?.schedule?.weekDays?.time?.end?.hour.toString().padStart(2, '0') }}:{{
                                            selectedFuturePosition?.role?.workSchedule?.schedule?.weekDays?.time?.end?.min.toString().padStart(2, '0')
                                          }}
                                          {{ selectedFuturePosition?.role?.workSchedule?.schedule?.weekDays?.time?.end?.meridiem }}
                                        )</span>
                                      }
                                      @if(selectedFuturePosition?.role.workSchedule.schedule.saturday.willWork){
                                        <span class="text-sm font-medium">SAT (
                                          {{
                                            selectedFuturePosition.role.workSchedule.schedule.saturday.time.start.hour.toString().padStart(2, '0')
                                          }}:{{
                                            selectedFuturePosition.role.workSchedule.schedule.saturday.time.start.min.toString().padStart(2, '0')
                                          }}
                                          {{
                                            selectedFuturePosition.role.workSchedule.schedule.saturday.time.start.meridiem
                                          }}
                                          --
                                          {{ selectedFuturePosition.role.workSchedule.schedule.saturday.time.end.hour.toString().padStart(2, '0') }}:{{
                                            selectedFuturePosition.role.workSchedule.schedule.saturday.time.end.min.toString().padStart(2, '0')
                                          }}
                                          {{ selectedFuturePosition.role.workSchedule.schedule.saturday.time.end.meridiem }}
                                        )</span>
                                      }
                                      @if(selectedFuturePosition?.role.workSchedule.schedule.sunday.willWork){
                                        <span class="text-sm font-medium">SUN (
                                          {{
                                            selectedFuturePosition.role.workSchedule.schedule.sunday.time.start.hour.toString().padStart(2, '0')
                                          }}:{{
                                            selectedFuturePosition.role.workSchedule.schedule.sunday.time.start.min.toString().padStart(2, '0')
                                          }}
                                          {{
                                            selectedFuturePosition.role.workSchedule.schedule.sunday.time.start.meridiem
                                          }}
                                          --
                                          {{ selectedFuturePosition.role.workSchedule.schedule.sunday.time.end.hour.toString().padStart(2, '0') }}:{{
                                            selectedFuturePosition.role.workSchedule.schedule.sunday.time.end.min.toString().padStart(2, '0')
                                          }}
                                          {{ selectedFuturePosition.role.workSchedule.schedule.sunday.time.end.meridiem }}
                                        )</span>
                                      }
                                  </div>
                                  <div class="col-6 lg:col flex flex-column">
                                      <span class="text-xs text-primary">POC</span>
                                      <span class="text-sm font-medium">{{selectedFuturePosition.role.reviewCenterLocation.pointOfContact}}</span>
                                      <span class="text-sm font-medium">{{selectedFuturePosition.role.reviewCenterLocation.email}}</span>
                                  </div>
                              </div>
                          </div>
                          <div class="flex flex-column gap-3">          
                          </div>
                          <div class="flex justify-content-between gap-2 md:flex-row flex-column-reverse">
                              <p-button [rounded]="true" (click)="quitPosition(selectedFuturePosition)" size="small" severity="danger" label="END ROLE"></p-button>
                              <div class="flex gap-2">
                                @if(documentsExist(this.selectedFuturePosition) || (this.selectedFuturePosition.firm.isDocusignEnabled && this.selectedFuturePosition.docusignEnabled)){
                                  <p-button (onClick)="openViewDocumentsModal(this.selectedFuturePosition)" [rounded]="true" [outlined]="true" size="small">VIEW DOCUMENTS</p-button>
                                }
                              </div>
                          </div>
                          }
                          </div>
                      </p-tabPanel>
                    }
                  </p-tabView>
                </p-panel>
            </div>
            @if(offers.length){
              <div class="col-12 lg:col-12 xl:col-4 sm:col-12 job-offer">
                <p-panel header="Job Offers" styleClass="p-panel--scondary">
                @for(offer of offers; track offer){
                      <div class="flex flex-column gap-3">
                          <div class="px-3 py-4 mb-5 bg-white">
                              <div class="grid justify-content-between">
                                  <div class="col-6 flex flex-column">
                                      <span class="text-xs text-primary">Role</span>
                                      <span class="text-sm font-medium">{{offer.role.roleType}}</span>
                                      
                                  </div>
                                  <div class="col-6 flex flex-column">
                                      <span class="text-xs text-primary">Hours</span>
                                      <span class="text-sm font-medium">{{offer?.role?.workSchedule?.workHours}}</span>
                                  </div>
                                  <div class="col-6 flex flex-column">
                                      <span class="text-xs text-primary">Rate</span>
                                      <span class="text-sm font-medium">${{offer.role.hourlyRate}}/hr</span>
                                  </div>
                                  @if(offer.role.overtime !== 0){
                                    <div class="col-6 flex flex-column">
                                      <span class="text-xs text-primary">Overtime</span>
                                      <span class="text-sm font-medium">${{offer.role.overTime}}/hr</span>
                                  </div>
                                  }
                                  <div class="col-6  flex flex-column">
                                      <span class="text-xs text-primary">Start Date</span>
                                      <span class="text-sm font-medium">{{offer.role.startAt | date:'MMM dd, YYYY'}} </span>
                                      <span class="text-sm font-medium">{{offer.role.startAt | date:'hh:mm a'}} - {{offer.role.timezone}}</span>
                                  </div>
                                  <div class="col-6 flex flex-column">
                                    <span class="text-xs text-primary">Address</span>
                                    @if(offer?.role?.reviewCenterLocation?.isRemote){
                                    <span class="text-sm font-medium">Remote</span>
                                    }@else{
                                      <span class="text-sm font-medium">{{offer?.role?.reviewCenterLocation?.reviewLocation?.location?.officeName+','}}</span>
                                      <span class="text-sm font-medium">{{offer?.role?.reviewCenterLocation?.reviewLocation?.location?.street+', '+    
                                      offer?.role?.reviewCenterLocation?.reviewLocation?.location?.suiteNo}}</span>
                                      <span class="text-sm font-medium">{{offer?.role?.reviewCenterLocation?.reviewLocation?.location?.city+', '+
                                      offer?.role?.reviewCenterLocation?.reviewLocation?.location?.state +', '+ offer?.role?.reviewCenterLocation?.reviewLocation?.location?.zipCode }}</span>
                                    }
                                    
                                   
                                </div>
                                  <div class="col-6  flex flex-column">
                                      <span class="text-xs text-primary">Schedule</span>
                                      @if(offer?.role?.workSchedule?.schedule?.weekDays?.willWork){
                                        <span class="text-sm font-medium">M-F (
                                          {{
                                            offer?.role?.workSchedule?.schedule?.weekDays?.time?.start?.hour.toString().padStart(2, '0')
                                          }}:{{
                                            offer?.role?.workSchedule?.schedule?.weekDays?.time?.start?.min.toString().padStart(2, '0')
                                          }}
                                          {{
                                            offer?.role?.workSchedule?.schedule?.weekDays?.time?.start?.meridiem
                                          }}
                                          --
                                          {{ offer?.role?.workSchedule?.schedule?.weekDays?.time?.end?.hour.toString().padStart(2, '0') }}:{{
                                            offer?.role?.workSchedule?.schedule?.weekDays?.time?.end?.min.toString().padStart(2, '0')
                                          }}
                                          {{ offer?.role?.workSchedule?.schedule?.weekDays?.time?.end?.meridiem }}
                                        )</span>
                                      }
                                      @if(offer?.role.workSchedule.schedule.saturday.willWork){
                                        <span class="text-sm font-medium">SAT (
                                          {{
                                            offer?.role?.workSchedule?.schedule?.weekDays?.time?.start?.hour.toString().padStart(2, '0')
                                          }}:{{
                                            offer?.role?.workSchedule?.schedule?.weekDays?.time?.start?.min.toString().padStart(2, '0')
                                          }}
                                          {{
                                            offer?.role?.workSchedule?.schedule?.weekDays?.time?.start?.meridiem
                                          }}
                                          --
                                          {{ offer?.role?.workSchedule?.schedule?.weekDays?.time?.end?.hour.toString().padStart(2, '0') }}:{{
                                            offer?.role?.workSchedule?.schedule?.weekDays?.time?.end?.min.toString().padStart(2, '0')
                                          }}
                                          {{ offer?.role?.workSchedule?.schedule?.weekDays?.time?.end?.meridiem }}
                                        )</span>
                                      }
                                      @if(offer?.role.workSchedule.schedule.sunday.willWork){
                                        <span class="text-sm font-medium">SUN (
                                          {{
                                            offer?.role?.workSchedule?.schedule?.sunday?.time?.start?.hour.toString().padStart(2, '0')
                                          }}:
                                          {{
                                            offer?.role?.workSchedule?.schedule?.sunday?.time?.start?.min.toString().padStart(2, '0')
                                          }}
                                          {{
                                            offer?.role?.workSchedule?.schedule?.sunday?.time?.start?.meridiem
                                          }}
                                          --
                                          {{ 
                                            offer?.role?.workSchedule?.schedule?.sunday?.time?.end?.hour.toString().padStart(2, '0')
                                          }}
                                          :
                                          {{
                                            offer?.role?.workSchedule?.schedule?.sunday?.time?.end?.min.toString().padStart(2, '0')
                                          }}
                                          {{ 
                                            offer?.role?.workSchedule?.schedule?.sunday?.time?.end?.meridiem 
                                          }}
                                        )</span>
                                      }
                                  </div>               
   
                      
                                  <div class="col-6 flex flex-column">
                                      <span class="text-xs text-primary">Duration</span>
                                      <span class="text-sm font-medium">{{offer.role.workSchedule.duration}}</span>
                                  </div>
                                  <div class="col-6 flex flex-column">
                                      <span class="text-xs text-primary">Qualification</span>
                                      @if(offer.role?.degrees?.length){
                                        <span class="text-xs">Degrees</span>
                                        @for(degree of offer.role.degrees; track degree){
                                          <span class="text-sm font-medium">{{degree.major}}</span>
                                        }
                                      }
                                      @if(offer.role?.criteriaTemplate?.length){
                                        <span class="text-xs">Skills and Experience</span>
                                        @for(skill of offer.role.criteriaTemplate; track skill){
                                            <span class="text-sm font-medium">{{skill.name}}</span>
                                          }
                                      }
                                      @if(offer.role?.credentialTemplate?.length){
                                        <span class="text-xs">Credentials</span>
                                        @for(cred of offer.role.credentialTemplate; track cred){
                                            <span class="text-sm font-medium">{{cred.name}}</span>
                                        }
                                      }

                                  </div>


                                  <div class="col-12 xl:ml-auto flex gap-2 xl:flex-wrap xl:justify-content-end">
                                      <p-button styleClass="freshgreen-background" (click)="openAcceptConfirmationModal(offer)" [rounded]="true" size="small">Accept</p-button>
                                      <p-button (click)="openRejectionConfirmationModal()" [rounded]="true" size="small" severity="danger">Reject</p-button>
                                  </div>
                              </div>
                          </div>
                      </div>
                    }
                </p-panel>               
              </div>
            }
           
            <!-- Accept Offer modal -->
           <p-dialog 
             [modal]="true" 
             draggable="false"
             [(visible)]="visibleAcceptConfirmationModal" 
             [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
             [style]="{ width: '660px' }" >

             <ng-template pTemplate="header">
                 <div class="inline-flex align-items-center justify-content-center gap-2">
                     <span class="font-bold white-space-nowrap">
                         Accept Offer
                     </span>
                 </div>
             </ng-template>

             <ng-template pTemplate="body">
               <div class="inline-flex align-items-center justify-content-center gap-2">
                   <span class="white-space-nowrap">
                       Are you sure you want to accept this offer?
                   </span>
               </div>
           </ng-template>

             <ng-template pTemplate="footer">
               <p-button styleClass="freshgreen-background" label="YES" size="large" [rounded]="true" (onClick)="accept($event,true)"/>
               <p-button severity="danger" label="NO" size="large" [rounded]="true" (onClick)="accept($event)"/>
             </ng-template>
           </p-dialog>

           <!-- Reject Offer modal -->
           <p-dialog 
             [modal]="true" 
             draggable="false"
             [(visible)]="visibleRejectConfirmationModal" 
             [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
             [style]="{ width: '660px' }" >

             <ng-template pTemplate="header">
                 <div class="inline-flex align-items-center justify-content-center gap-2">
                     <span class="font-bold white-space-nowrap">
                         Reject Offer
                     </span>
                 </div>
             </ng-template>

             <ng-template pTemplate="body">
               <div class="align-items-center justify-content-center gap-2 mb-3">
                   <span class="white-space-nowrap">
                     Why did you Rejected this offer
                   </span>
               </div>
               <div class="align-items-center justify-content-center gap-2">
                 <p-floatLabel>
                   <p-dropdown 
                       [options]="offerRejectReasons"
                       [(ngModel)]="selectedReason"
                       optionLabel="label" 
                       appendTo="body" 
                       inputId="float-label"/>
                     <label for="float-label">Reason</label>
                   </p-floatLabel>
               </div>
           </ng-template>

             <ng-template pTemplate="footer">
               <p-button severity="danger" label="Reject Offer" size="large" [rounded]="true" (onClick)="offerReject(true)"/>
               <p-button label="Cancel" size="large" [rounded]="true" (onClick)="offerReject()"/>
             </ng-template>
           </p-dialog>
        </div>
    </div>
</div>