import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectService } from '../../../../../../../../shared/services/project.service';
import { ToastService } from '../../../../../../../../shared/services/toast.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { UserService } from '../../../../../../../../shared/services/user.service';
import { lastValueFrom } from 'rxjs';
import { UserRoles } from '../../../../../../../../shared/constants/constants';
import { DropDown } from '../../../../../settings/settings.model';
import { ProjectManagers } from '../../../../project.interface';
import { FirmService } from '../../../../../../../../shared/services/firm.service';

@Component({
  selector: 'app-unarchive-project',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FloatLabelModule,
    CalendarModule,
    InputTextModule,
    FileUploadModule,
    ReactiveFormsModule,
    InputTextareaModule,
    DropdownModule,
    FormsModule,
  ],
  templateUrl: './unarchive-project.component.html',
  styleUrl: './unarchive-project.component.scss'
})
export class UnarchiveProjectComponent implements OnInit {
  projectOwner!: string;
  userFirmId!: string;
  projectManagers!: ProjectManagers[];
  projectName!: string;
  managerDropdown: DropDown[] = [{label: '', value: ''}];
  selectedManager!: DropDown;
  constructor(
    public config: DynamicDialogConfig,    
    private fb: FormBuilder,
    private projectService: ProjectService,
    public ref: DynamicDialogRef,
    private toastService: ToastService,
    private userService: UserService,
    private firmService: FirmService
  ) {}

  ngOnInit(): void {
    this.userFirmId = this.userService.getUserFirmId();
    this.projectOwner = this.config.data[0];
    this.projectName = this.config.data[1];
    this.getProjectManagers();
  }

  async getProjectManagers(){
    const res = await lastValueFrom(this.firmService.getListOfFirmUsers(this.userFirmId));
    if(res && res.users && res.users.length){
      this.projectManagers = res.users.filter((user: any) => user.isActive);
      this.setManagerDropdown();
    }
  }

  setManagerDropdown(){
    this.managerDropdown.length = 0;
    this.projectManagers.forEach((item: any) => {
      this.managerDropdown.push({
        label: item?.firstName,
        value: item?._id,
      });
    });
    const selected = this.managerDropdown.find(manager => manager.value === this.projectOwner);
    if(selected){
     this.selectedManager = selected;
    }    
  }

  handleSubmit(){
    this.ref.close(this.selectedManager);
  }
}
