<div class="align-items-center">
    <div class="col-12 mb-3 p-0">
        <div class="flex  flex-column align-items-start justify-content-center gap-2">
            <span class="font-medium text-sm">Thank You for Working with Project: <b>{{ratingInfo?.project?.codeName}}</b></span>
        </div>
    </div>
    <div class="col-12 mb-3 p-0">
        <div class="w-full flex flex-column gap-3 pb-3">
            <div class="align-items-center border-bottom-1 border-top-1 flex justify-content-between px-4 py-2 surface-border">
                <div class="flex align-items-center gap-4 text-sm">
                    <p>
                        Please take a moment to rate your experience on Project: <b>{{ratingInfo?.project?.codeName}}</b> Role: <b>{{ratingInfo?.role?.roleType}}</b>. The Mplace matching algorithm will use your rating to prioritize future offers.
                    </p>
                </div>
                <div class="flex align-items-center">
                    <p-rating [(ngModel)]="rating" [cancel]="false" pTooltip="Overall Rating" tooltipPosition="top" class="p-rating--sm" />
                </div>
            </div>
            
        </div>
    </div>
    <div class="flex justify-content-end gap-2">
        <p-button label="Submit Rating" [size]="'large'" (click)="onConfirm(true)" />
    </div>
</div>