import { Component, ViewEncapsulation, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HeadingComponent } from '../../../../shared/components/heading/heading.component';
import { ProjectService } from '../../../../shared/services/project.service';
import { DocumentService } from '../../../../shared/services/document-service';
import { UserService } from '../../../../shared/services/user.service';
import { ConfirmationDialogService } from '../../../../shared/services/confirmation-dialog.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { UserClientVm } from '../../../../store/user/user.interface';
import { ToastService } from '../../../../shared/services/toast.service';
import { HelperService } from '../../../../shared/services/helper.service';
import { constants } from '../../../../shared/constants/constants';
import { PermanentlyAddConfirmationComponent } from './permanently-add-confirmation-modal/permanently-add-confirmation-modal.component';
import { EditTemplateService } from '../project/create/documents/edit-template/edit-template.service';
import { EditTemplateComponent } from '../project/create/documents/edit-template/edit-template.component';
@Component({
  selector: 'app-docusign-templates',
  standalone: true,
  imports: [MultiSelectModule, FloatLabelModule, DialogModule, CheckboxModule, TabViewModule, BreadcrumbModule, DropdownModule, ButtonModule, FormsModule, ChipModule, IconFieldModule, InputIconModule, HeadingComponent, InputTextModule, TableModule, CommonModule, ConfirmPopupModule, ReactiveFormsModule, EditTemplateComponent],
  templateUrl: './docusign-templates.component.html',
  styleUrl: './docusign-templates.component.scss',
  providers: [DialogService],
  encapsulation: ViewEncapsulation.None,
})
export class DocuSignTemplatesComponent implements OnInit {
  @ViewChild('docusignUpload') docusignUpload!: ElementRef;
  checked: boolean = false;
  expandedRows = {};
  templates: any;
  allTemplates: Array<any> = [];
  searchText = '';
  items: any;
  user!: UserClientVm;
  ref: DynamicDialogRef | undefined;
  docusignUploadForm!: FormGroup;
  docSignTemplate: any = null;
  visibleDocusignUploadModal = false;
  projectFiles: any = null;
  documents: Array<any> = [];

  uploadFileObject: any = null;
  firmId: any = null;
  docuSignEditorOpen = false;
  isViewOnly = false;
  projectId = '';

  constructor(private documentService: DocumentService, private userService: UserService,
    private confirmationDialogService: ConfirmationDialogService, private toastService: ToastService, private fb: FormBuilder, private helperService: HelperService, public dialogService: DialogService, 
    private projectService: ProjectService, private editTemplateService: EditTemplateService) { }

  ngOnInit() {

    this.docusignUploadForm = this.fb.group({
      docTitle: ['', [Validators.required]],
      instructions: ['']
    })
    this.user = this.userService.getSessionUser();
    this.firmId = this.user.firm._id;

    this.getFirmDocuSignTemplates();
    this.items = [
      { label: 'Firm' },
      { label: 'Document' },
      { label: 'Templates' },
    ];

  }

  getFirmDocuSignTemplates() {
    this.documentService.getFirmDocuSignTemplates(this.user.firm._id, '').subscribe({
      next: (templates) => {
        this.allTemplates = templates.envelopeTemplates;
        if (!this.allTemplates.length) {
          this.toastService.showSuccess('No Templates found');
        } else {
          this.templates = this.allTemplates.slice();
        }
      }
    })
  }

  deleteTemplateDocument($event: Event, documentId: string, templateId: string, docSignTemplate: any) {
    if (docSignTemplate.createdBy !== this.user._id) {
      this.toastService.showError('You are not authorized to delete this template');
      return;
    }
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: 'Are you sure you want to delete this ' + (docSignTemplate.documents.length === 1 ? 'template?' : 'document?'),
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.documentService.deleteDocuSignDocument(documentId, templateId, docSignTemplate._id).subscribe((res) => {
          this.toastService.showSuccess(docSignTemplate.documents.length === 1 ? 'Document has been deleted successfully' : 'Document' + ' has been deleted');
          this.getFirmDocuSignTemplates();
        });
      }
    });
  }

  deleteTemplate($event: Event, templateId: string, docSignTemplate: any) {
    if (docSignTemplate.createdBy !== this.user._id) {
      this.toastService.showError('You are not authorized to delete this template');
      return;
    }

    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: 'Are you sure you want to delete this template?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.documentService.deleteDocuSignTemplate(templateId, docSignTemplate._id).subscribe((res) => {
          this.toastService.showSuccess('Document has been deleted successfully');
          this.getFirmDocuSignTemplates();
        });
      }
    });
  }

  onRowExpand(event: TableRowExpandEvent) { }
  onRowCollapse(event: TableRowCollapseEvent) { }

  get docusignForm(): { [key: string]: AbstractControl } {
    return this.docusignUploadForm.controls;
  }

  showUploadDocumentDialog(docSignTemplate: any): void {
    if (docSignTemplate.createdBy !== this.user._id) {
      this.toastService.showError('You are not authorized to add document in this template');
      return;
    }
    this.docSignTemplate = docSignTemplate;
    this.visibleDocusignUploadModal = true;

  }

  onUploadChange(event: any) {
    const files = event.target.files;
    if (files.length) {
      this.projectFiles = files;
    }
  }

  onSearch(): void{
    this.templates.length = 0;
    if (this.searchText) {
      const searchText = this.searchText.toLowerCase();
      this.templates = this.allTemplates.filter(template => {
        return (
          template.name.toLowerCase().includes(searchText)
        )
      });

    } else {
      this.templates = this.allTemplates.slice();
    }
  }

  onRemoveChip(type: string) {
    this.searchText = '';
    this.templates = this.allTemplates.slice();
  }

  isFileUploadValid(): boolean {

    if (this.docusignUploadForm && !this.docusignUploadForm.valid) {
      return false;
    }

    if (this.projectFiles && !this.isFileTypeAllowed(this.projectFiles)) {
      this.toastService.showError('Only pdf file type is allowed.');
      return false;
    }

    if (this.projectFiles && this.helperService.isGreaterThanAllowedSize(this.projectFiles)) {
      this.toastService.showError("Document size exceeding limit must be " + constants.documentUploadSizeInMbs + "mb or less.");
      return false;
    }

    if (!this.projectFiles || !this.projectFiles.length) {
      this.toastService.showError("Select document.");
      return false;

    }

    this.uploadFileObject = {
      file: this.projectFiles,
      title: this.docusignUploadForm.value.docTitle
    }

    return true;
  }

  isFileTypeAllowed(files: any) {
    let valid = true;
    for(let i = 0; i < files.length; i++){
      if (!files[0].type.includes('pdf')) {
        valid = false;
      }
    }
    return valid;
  }

  addDocumentInTemplate(): void {
    if (!this.isFileUploadValid()) {
      return;
    }

    if (!this.docSignTemplate) {
      return;
    }

    if(!this.uploadFileObject){
      return;
    }

    const document = this.uploadFileObject.file[0];
    this.documents = this.documents.concat(this.uploadFileObject.file[0]);
    const fileOptions = this.documents.map((doc) => {
      return {
        projectId: this.docSignTemplate.projectId,
        name: doc.name,
        docTitle: this.uploadFileObject.title
      };
    });

    const projectId = this.docSignTemplate.projectId;
    this.visibleDocusignUploadModal = false;


    this.ref = this.dialogService.open(PermanentlyAddConfirmationComponent, {
      header: 'Confirmation',
      width: '70vw',
      height: '30vh',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
    this.ref.onClose.subscribe({
      next: (isConfirm: boolean) => {
        if (isConfirm) {
          this.projectService.updateDocuSignTemplate(document, fileOptions, this.docSignTemplate.name, this.docSignTemplate.templateId, this.docSignTemplate?.documents?.length).subscribe({
            next: () => {
              this.getFirmDocuSignTemplates();
              this.toastService.showSuccess("Document added successfully.");
              this.setToDefaultFileUploadConfig();
            }
          });

        } else {
          const documents = this.documents.map((doc) => {
            return doc;
          });

          const templateFields: any = {};
          this.documents.forEach((document, index) => {
            for (let page in document.templateFields) {
              for (let fieldType in document.templateFields[page]) {
                if (!Array.isArray(templateFields[fieldType])) {
                  templateFields[fieldType] = [];
                }
                const fields: any = Object.values(document.templateFields[page][fieldType]);
                for (let fieldI = 0; fieldI <= fields.length; fieldI++) {
                  if (fieldType === 'radioTab' && fields.length > 0) {
                    const isDomIdExist: any = fields[fieldI];
                    if (isDomIdExist && isDomIdExist.domid) {
                      let radioFieldObject = this.getRadioGroupField(index, page, fields, fieldI, document.templateFields);
                      templateFields[fieldType].push(radioFieldObject);
                    }
                  }
                  else if (fields[fieldI] && Object.values(fields[fieldI]).length) {
                    templateFields[fieldType].push({
                      xPosition: Math.round(this.getXPositionOfFieldType(fields[fieldI].left, fieldType)),
                      yPosition: Math.round(this.getYPositionFieldType(fields[fieldI].top, fieldType)),
                      documentId: index + 1,
                      pageNumber: page,
                      groupId: fields[fieldI].groupId,
                      tabGroupLabel: fields[fieldI].tabGroupLabel,
                      tabLabel: fields[fieldI].tabLabel,
                      groupTooltip: fields[fieldI].groupTooltip,
                      validationType: fields[fieldI].validationType,
                      validationCount: fields[fieldI].validationCount,
                      validationCountMin: fields[fieldI].validationCountMin,
                      validationCountMax: fields[fieldI].validationCountMax,
                      conditionalFields: fields[fieldI].conditionalFields ? fields[fieldI].conditionalFields.map((condition: any) => { return { selectedElement: condition.selectedElement, checked: condition.checked }; }) : null,
                      height: fields[fieldI].height,
                      width: fields[fieldI].width,
                      editable: fields[fieldI].editable,
                      tooltip: fields[fieldI].tabName,
                      tabName: fields[fieldI].tabName,
                      required: fields[fieldI].required
                    });
                  }
                }
              }
            }
          });

          this.projectService.createProjectTemplate(projectId, this.firmId, documents, fileOptions, this.uploadFileObject.title, this.getTemplateFieldsOrDefault(templateFields)).subscribe({
            next: (response) => {
              this.getFirmDocuSignTemplates();
              this.toastService.showSuccess('Template created successfully.');
              this.setToDefaultFileUploadConfig();
            }
          })
        }
      }
    });
  }

  getRadioGroupField(documentIdIndex: number, pageId: any, fields: any, fieldAt: any, documentFields: any) {
    const selectedRadioObject = fields[fieldAt];

    if (!selectedRadioObject) {
      return selectedRadioObject;
    }
    selectedRadioObject.documentId = documentIdIndex + 1;
    selectedRadioObject.pageNumber = pageId;
    selectedRadioObject.radioGroupTabs = [];

    const selectedRadioGroupValues = Object.values(selectedRadioObject.radioGroup);
    if (selectedRadioObject.top === undefined || selectedRadioObject.left === undefined) {
      selectedRadioObject.top = constants.defaultDocuSignFieldsCoordinates['radioTab'].top;
      selectedRadioObject.left = constants.defaultDocuSignFieldsCoordinates['radioTab'].left;
    }
    selectedRadioGroupValues.map((radio: any, index: number) => {
      const radioTabOption = documentFields[pageId]['radioTab'][radio.id];
      radio.tabName = radioTabOption ? radioTabOption.tabName : '';
      radio.selected = !!radio.selected;
      radio.documentId = documentIdIndex + 1;
      radio.pageNumber = pageId;
      if (selectedRadioObject.alignHorizontal) {
        radio.xPosition = selectedRadioObject.left + ((index + 1) * 28 - 11);
        radio.yPosition = selectedRadioObject.top + 15;
      } else {
        radio.xPosition = selectedRadioObject.left;
        radio.yPosition = selectedRadioObject.top + ((index + 1) * 28 - 11);
      }
      selectedRadioObject.radioGroupTabs.push(radio);
    });

    delete selectedRadioObject.radioGroup;
    delete selectedRadioObject['$$hashKey'];

    return selectedRadioObject;
  }

  getXPositionOfFieldType(fieldValue: any, fieldType: string) {
    if (fieldValue === undefined) {
      const defaultDocuSignFieldsCoordinates: any = constants.defaultDocuSignFieldsCoordinates;
      const defaultFieldValue = defaultDocuSignFieldsCoordinates[fieldType];
      return defaultFieldValue.left;
    }

    return fieldValue;
  }

  getYPositionFieldType(fieldValue: any, fieldType: string) {
    if (fieldValue === undefined) {
      const defaultDocuSignFieldsCoordinates: any = constants.defaultDocuSignFieldsCoordinates;
      const defaultFieldValue = defaultDocuSignFieldsCoordinates[fieldType];
      return defaultFieldValue.top;
    }

    return fieldValue;
  }

  getTemplateFieldsOrDefault(templateFileds: any) {
    if(Object.keys(templateFileds).length === 0) {
      let obj = {
        signHere:[],
        dateSigned:[],
        textTab_fullName:[],
        textTab_email:[],
        textTab_custom:[],
        checkBoxTab:[],
        initialHere:[],
        radioTab:[],
        signerAttachment:[]
      };
      return obj;
    }
    return templateFileds;
  }

  setToDefaultFileUploadConfig(): void{
    this.uploadFileObject = null;
    this.docSignTemplate = null;
    this.projectFiles = null;
    this.docusignUploadForm.controls['docTitle'].setValue('');
    this.docusignUpload.nativeElement.value = '';
    this.documents = [];
  }

  openDocuSignEditor(event: any){
    this.editTemplateService.setDocumentInfo({});
    this.docuSignEditorOpen = false;
  }

  viewTemplateDocument(templateId: string, documentId: string, documentName: string, docuSignTemplate: any){
    this.documentService.getDocuSignDocument(templateId, documentId).subscribe({
      next: async (res) => {
        const url = URL.createObjectURL(new Blob([res]));
        const response = await fetch(url);
        const blob = await response.blob();
        const pdfContent = await this.getFileReaderObject(blob);
        
        const docIndex =  docuSignTemplate.documents.findIndex((doc: any) => {
          return doc.name === documentName;
        });
        const documentUri = pdfContent;;
        this.editTemplateService.setDocumentInfoByKey('editTemplate',
          { 
            documentId: docIndex+1, 
            documentUri,
            docuSignTemplate 
          }
        );
        this.isViewOnly = true;
        this.docuSignEditorOpen = true;
      },
      error: (error) => {
      }
    })
  }

  getFileReaderObject(blob: any): Promise<any>{
    const reader = new FileReader();
    
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(new Error('Failed to read the blob content'));
      };
      
      reader.readAsText(blob);
    });
  }

}
