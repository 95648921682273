import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { HeadingComponent } from '../../../../shared/components/heading/heading.component';
import { ProjectService } from '../../../../shared/services/project.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DocumentService } from '../../../../shared/services/document-service';
import { TooltipModule } from 'primeng/tooltip';
import { ToastService } from '../../../../shared/services/toast.service';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-documents',
  standalone: true,
  imports: [ MultiSelectModule,FloatLabelModule,DialogModule,CheckboxModule,TabViewModule, BreadcrumbModule, DropdownModule, ButtonModule, FormsModule, ChipModule, IconFieldModule, InputIconModule, HeadingComponent, InputTextModule, TableModule, CommonModule, TooltipModule, ToastModule],
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DocumentsComponent implements OnInit {
  checked: boolean = false;
  expandedRows: { [key: string]: boolean } = {};
  expandedRoles: { [key: string]: boolean } = {};
  expandedReviewers: { [key: string]: boolean } = {};
  projects: any = [];
  allProjects: Array<any> = [];
  searchText = '';
  items: any;

  constructor(private projectService: ProjectService, private documentService: DocumentService, private toastService: ToastService) { }

  ngOnInit() {
    this.getProjectDocuments();

    this.items = [
      { label: 'Firm' }, 
      { label: 'Document' }, 
      { label: 'Details' },
    ];
  }

  getProjectDocuments(){
    this.projectService.getProjectDocuments().subscribe({
      next: (projects) => {
        this.projects = projects.data;
        this.allProjects = this.projects;
        if (!this.allProjects.length) {
          this.toastService.showSuccess('No Projects found');
        } else {
          this.projects = this.allProjects.slice();
        }
      }
    })
  }

  downloadDocument(projectId: string, roleId?: string){
    let url = this.documentService.getDocuSignDownloadUrl(projectId, roleId!);
    window.open(url, '_blank')
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data._id] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data._id];
  }

  onRoleExpand(event: any) {
    this.expandedRoles[event.data._id] = true;
  }

  onRoleCollapse(event: any) {
    delete this.expandedRoles[event.data._id];
  }

  onReviewerExpand(event: any) {
    this.expandedReviewers[event.data._id] = true;
  }

  onReviewerCollapse(event: any) {
    delete this.expandedReviewers[event.data._id];
  }

  async downloadDocuSignEnvelopeDocuments(projectId: string, roleId: string, reviewerId: string, positionId: string, reviewerName: string): Promise<void>{
    try {
      const res = await this.documentService.getEnvelopeDocumentsListing(projectId, roleId, reviewerId, positionId , true, false, true);
      

      const url = URL.createObjectURL(new Blob([res]));
      const response = await fetch(url);
      const blob = await response.blob();
      const pdfContent = await this.documentService.getFileReaderObject(blob);

      const uri = `data:application/octet-stream,${window.escape(pdfContent)}`;

      const downloadLink = document.createElement("a");
      downloadLink.href = uri;
      downloadLink.download = 'envelope-'+ reviewerName+'.pdf';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
        this.toastService.showError('No Docusign document found');
    }
  }

  onSearch(): void{
    this.projects.length = 0;
    if (this.searchText) {
      const searchText = this.searchText.toLowerCase();
      this.projects = this.allProjects.filter(doc => {
        return (
          doc.codeName.toLowerCase().includes(searchText)
        )
      });

    } else {
      this.projects = this.allProjects.slice();
    }
  }

  onRemoveChip(type: string) {
    this.searchText = '';
    this.projects = this.allProjects.slice();
  }
}
