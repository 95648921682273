import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputNumberModule } from 'primeng/inputnumber';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { Column, Project } from '../../project.interface';
import { RolesService } from '../../../../../../shared/services/roles.service';
import { Role } from '../../../../../../shared/interfaces/roles.interface';
import { ReviewerService } from '../../../../../../shared/services/reviewers.service';
import { Reviewer } from '../../../../../../shared/interfaces/reviewer.interface';
import { OfferConfirmationModalComponent } from './modals/offer-confirmation-modal/offer-confirmation-modal.component';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OfferService } from '../../../../../../shared/services/offers.service';
import { Offers } from '../../../../../../shared/interfaces/offers.interface';
import { TalentPoolService } from '../../../../../../shared/services/talent-pool.service';
import { TalentPool } from '../../../../../../shared/interfaces/talent-pool.interface';
import { TalentpoolModalComponent } from './modals/talent-pool-modal/talent-pool-modal.component';
import { constants } from '../../../../../../shared/constants/constants';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { RatingModule } from 'primeng/rating';
import { ReviewerDetailDialogService } from '../../../../../../shared/services/reviewer-detail-dialog.service';
import { TooltipModule } from 'primeng/tooltip';
import { RoleService } from '../../../../../../shared/services/role.service';
import { ConfirmationDialogService } from '../../../../../../shared/services/confirmation-dialog.service';

@Component({
  selector: 'app-project-offers',
  standalone: true,
  imports: [
    DragDropModule,
    ChipModule,
    TabViewModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    InputTextareaModule,
    FormsModule,
    RatingModule,
    RadioButtonModule,
    FloatLabelModule,
    InputTextModule,
    InputSwitchModule,
    MultiSelectModule,
    CommonModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    IconFieldModule,
    InputIconModule,
    InputNumberModule,
    OverlayPanelModule,
    TableModule,
    DynamicDialogModule,
    TooltipModule
  ],
  providers: [DialogService],
  templateUrl: './offers.component.html',
  styleUrl: './offers.component.scss'
})
export class OffersComponent implements OnInit {

  roles: Array<Role> = [];
  selectedRole: Role | null = null;
  updatedRole: Role | null = null;
  reviewers: Array<Reviewer> = [];
  autogeneratedReviewers: Array<Reviewer> = [];
  selectedReviewers: Array<Reviewer> = [];
  shortlistedReviewers: Array<Reviewer> = [];
  selectedShortlistedReviewers: Array<Reviewer> = [];
  selectedOfferedQueueReviewers: Array<Reviewer> = [];
  sendOfferRef: DynamicDialogRef | undefined;
  sendBulkOfferRef: DynamicDialogRef | undefined;
  resumeOfferRef: DynamicDialogRef | undefined;
  pauseOfferRef: DynamicDialogRef | undefined;
  talentPoolRef: DynamicDialogRef | undefined;
  loadingReviewers:boolean = false;
  continueSendingOfferCheckBox:boolean = false;
  cols: Column[] = [
    { field: 'name', header: 'Name' },
    { field: 'favorite', header: ' '},
    { field: 'rating', header: 'Rating' },
    { field: 'together', header: 'Projects Together' },
    { field: 'actions', header: 'Action' },
  ];
  
  fixedCols = [
    ...this.cols
  ];
  categories: any[] = [
    { name: 'None', key: '' },
    { name: 'Worked Together', key: 'workedTogetherReviewers' },
    { name: 'In-House', key: 'occupied' },
    { name: 'Restricted', key: 'restrictedReviewers' },
    { name: 'Conflicted', key: 'conflicted' },
    { name: 'Rejected', key: 'declined' },
    { name: 'Previously Assigned ', key: 'firedQuit' },
    { name: 'Talent Pool', key: 'talentPool' }
  ];

  sortOptions: any[] = [
    { name: 'Sort By', key: '' },
    { name: 'Activity (recently logged in)', key: 'Activity' },
    { name: 'Date Invited', key: 'Date Verified' },
    { name: 'Projects Together', key: 'Projects Worked' },
    { name: 'Star Rating', key: 'Star Rating' },
    { name: 'Recently Available', key: 'Recently Available' }
  ];
  selectedSortOption: Array<any> = [this.sortOptions[0]];

  selectedFilter: any = this.categories[0];

  talentPools: Array<TalentPool> = [];
  category!: string;
  @Input()
  id: string | null = null;
  @Input()
  project: Project | null = null;
  @Input() isDefaultSelectedRole = false;
  @Output()
  updateProjectState: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  changeTab: EventEmitter<number> = new EventEmitter<number>();

  selectedPoolIds: Array<string> = [];
  isFullTimePermanantProject = false;
  isUnPublishedProject = false;

  initialPosition = 0;
  showPositionSaveButton = false;

  initialHour = 0;
  showHourSaveButton = false;

  initialOvertime = 0;
  showOvertimeSaveButton = false;


  savingHourlyRate = false;

  searchDebounce: any = null;

  searchShortListedDebounce: any = null;
  searchOfferDebounce: any = null;

  totalReviewersCount: number = 0;

  searchValue = '';
  searchShortListed = '';
  searchOfferValue = '';
  showReviewerSearchError = false;

  lastPage = false;
  pagination = constants.pagination.offers;

  activeTabIndex = 0;

  showResumeButton = false;

  offersReviewersCount = 0;

  shortListedReviewersCount = 0;

  columnConfigs: { [key: string]: Column[] } = {
    workedTogetherReviewers: [{ field: 'lastAssigned', header: 'Last Assigned' }],
    occupied: [
      { field: 'currentProject', header: 'Current Project' },
      { field: 'projects', header: 'Projects' },
    ],
    conflicted: [{ field: 'conflicts', header: 'Conflicted Questions'}],
    firedQuit: [{field: 'firedQuit', header: 'Previously Assigned'}]
  };

  constructor(
    private rolesService: RolesService,
    private reviewerService: ReviewerService,
    private dialogService: DialogService,
    private offerService: OfferService,
    private talentPoolService: TalentPoolService,
    private projectService: ProjectService,
    private toast: ToastService,
    private reviewerDetailDialogService:ReviewerDetailDialogService,
    private roleService: RoleService,
    private confirmationDialogService: ConfirmationDialogService,
  ) {

  }

  async ngOnInit() {
    await this.getRolesByProjectId();
    this.setDefaultRole();
    this.initializeProjectAndRole();
    await this.getReviewersByProjectAndRoleId();
    await this.getTalentPools();
  }

  async getTalentPools(): Promise<void> {
    this.talentPools = await this.talentPoolService.getPoolsList();
  }

  async getRolesByProjectId(): Promise<void> {
    this.roles = await this.rolesService.getActiveRolesByProjectId(this.id || '');
  }

  async onRoleChange(): Promise<void> {
    this.onInputChange();

  }

  async onInputChange(): Promise<void>{
    this.initializeProjectAndRole();
    await this.getReviewersByProjectAndRoleId();
    await this.getRoleByProjectAndRoleID();
    await this.initializeShortListedReviewer();
    await this.initializeOfferQueueReviewers();
    this.initializeResumeButton();
    this.continueSendingOfferCheckBox = false;
  }

  setDefaultRole(): void {
    if(this.isDefaultSelectedRole ) {
      this.selectedRole = this.roles.length ? this.roles[0] : null;
    }
    else {
      this.selectRoleForOffer(this.roleService.role);
    }
  }

  initializeProjectAndRole() {
    this.isUnPublishedProject = constants.projectStatus.created === this.project?.status;
    if (this.selectedRole) {
      this.isFullTimePermanantProject = this.selectedRole.project?.workSchedule?.duration === 'Permanent/Full-Time';


      this.selectedRole.disableOvertime = false;
      this.selectedRole.positions = this.selectedRole.positions ? this.selectedRole.positions : 0;
      this.initialPosition = this.selectedRole.positions;
      this.selectedRole.positionsMin = this.selectedRole.positions;
      this.selectedRole.hourlyRate = this.selectedRole.hourlyRate ? this.selectedRole.hourlyRate : 0;
      this.initialHour = this.selectedRole.hourlyRate;
      this.selectedRole.overtimeSelected = false;
      if (this.selectedRole.overTime && this.selectedRole.overTime > 0) {
        this.selectedRole.overtimeSelected = true;
        this.selectedRole.disableOvertime = !this.isUnPublishedProject;
      }
      this.selectedRole.overTime = this.selectedRole.overTime ? this.selectedRole.overTime : 0;
      this.initialOvertime = this.selectedRole.overTime || 0;
      if (this.isUnPublishedProject) {
        this.selectedRole.occupiedPositions = 0;
        this.selectedRole.hourlyRateMin = 0;
        this.selectedRole.hourlyRateSaved = 0;
        this.selectedRole.overTimeMin = this.offerService.calculateOvertime(this.selectedRole) || 0;
        this.selectedRole.overTimeSaved = 0;
      } else {
        this.selectedRole.occupiedPositions = (this.selectedRole.occupiedPositions || 0);
        this.selectedRole.hourlyRateMin = this.selectedRole.offerSent ? this.selectedRole.hourlyRate : 0;
        this.selectedRole.hourlyRateSaved = this.selectedRole.hourlyRate;
        this.selectedRole.overTimeMin = this.selectedRole.offerSent ? this.selectedRole.overTime : this.offerService.calculateOvertime(this.selectedRole);
        this.selectedRole.overTimeSaved = this.selectedRole.overTime;
        this.selectedRole.disableOvertime = (this.selectedRole.overTime > 0 && this.selectedRole.offerSent);

      }
    }
  }

  onChangePosition(): void {
    if (!this.selectedRole) {
      return;
    }
    if (this.selectedRole.positions !== this.initialPosition) {
      this.showPositionSaveButton = true;
    } else {
      this.showPositionSaveButton = false;
    }
  }

  onChangeHour(): void {
    if (!this.selectedRole) {
      return;
    }
    if (this.selectedRole.hourlyRate !== this.initialHour) {
      this.showHourSaveButton = true;
    } else {
      this.showHourSaveButton = false;
    }
  }

  toggleContinueSendingOfferOnRole(event:any): void {
    if(this.selectedRole){
      this.selectedRole.continueSendingOffers = this.continueSendingOfferCheckBox;
    }

    if(this.selectedShortlistedReviewers.length === 0 && this.continueSendingOfferCheckBox === true){

      this.openBulkSendOfferModal(true);
    }
  }

  onChangeOvertime(): void {
    if (!this.selectedRole) {
      return;
    }
    if (this.selectedRole.overtimeSelected && this.selectedRole.overTime > 0 && this.selectedRole.overTime !== this.initialOvertime) {
      this.showOvertimeSaveButton = true;
    } else {
      this.showOvertimeSaveButton = false;
    }
  }

  async updatePositions(positions: number): Promise<any> {
    if (!this.selectedRole) {
      return;
    }
    if (!positions || positions < this.selectedRole?.occupiedPositions) {
      this.toast.showError(constants.select.error.minimumDesiredFreelancer);
      return;
    }
    const data = { positions };
    await this.projectService.updateProjectRole(this.id || '', this.selectedRole._id, data);
    this.selectedRole.positionsMin = positions;
    this.initialPosition = positions;
    this.showPositionSaveButton = false;
    await this.onInputChange();
  }


  async updateHourlyRate(hourlyRate: number, overtime: number, payType: string): Promise<void> {
    if (!this.selectedRole) {
      return;
    }
    if (!hourlyRate) {
      this.toast.showError(constants.select.error.minimumHourlyRate);
      return;
    }
    if (this.selectedRole.offerSent && hourlyRate < this.selectedRole.hourlyRateSaved) {
      this.toast.showError('Hourly rate cannot be reduced once offers are sent');
      return;
    }
    const data: any = { hourlyRate, payType: payType || 'hourly' };
    if (this.selectedRole.overtimeSelected) {
      data.overTime = overtime;
    }

    this.savingHourlyRate = true;
    await this.projectService.updateProjectRole(this.id || '', this.selectedRole._id, data);
    this.selectedRole.hourlyRateMin = this.selectedRole.offerSent ? hourlyRate : 0;
    this.selectedRole.hourlyRateSaved = hourlyRate;
    this.showHourSaveButton = false;
    this.savingHourlyRate = false;
    if (this.selectedRole.overtimeSelected) {
      this.selectedRole.overTimeMin = overtime;
      this.selectedRole.overTimeSaved = overtime;
    }
    await this.onInputChange();

  }

  async updateOvertime(overTime: number): Promise<void> {
    if (!this.selectedRole) {
      return;
    }
    if (this.selectedRole.overtimeSelected && !overTime) {
      this.toast.showError('Overtime should be greater than 0');
      return;
    }
    if (this.selectedRole.offerSent && (!this.selectedRole.overtimeSelected || overTime < this.selectedRole.overTimeSaved)) {
      this.toast.showError('Overtime cannot be reduced once offers are sent');
      return;
    }

    const data = { overTime };

    await this.projectService.updateProjectRole(this.id || '', this.selectedRole._id, data);

    this.selectedRole.overTimeMin = this.selectedRole.offerSent ? overTime : this.selectedRole.overTimeMin;
    this.selectedRole.overTimeSaved = overTime;
    this.showOvertimeSaveButton = false;
    this.initialOvertime = overTime || 0;
    await this.onInputChange();
  }

  onChangeHourlyRate(): void {
    this.showHourSaveButton = true;
  }

  searchReviewers(): void {
    if(!this.searchValue.length){
      this.showReviewerSearchError = false;
      this.getReviewersByProjectAndRoleId();
      return;
    }
    if(this.searchValue.length < 3){
      this.showReviewerSearchError = true;
      return;
    }
    this.showReviewerSearchError = false;
    if (this.searchDebounce) {
      clearTimeout(this.searchDebounce);
    }
    this.searchDebounce = setTimeout(() => {
      if(this.searchValue.length >= 3){
        this.getReviewersByProjectAndRoleId();
      }
    }, 1000);
  }

  toggleOvertime(){
    if(this.selectedRole?.overtimeSelected){
      this.initialOvertime = Number(this.selectedRole?.hourlyRate)*1.5;
      this.selectedRole.overTime = Number(this.selectedRole?.hourlyRate)*1.5;
    } else {
      this.initialOvertime = 0;
    }
    this.onChangeOvertime();
  }

  onRemoveChip() {
    this.searchValue = '';
    this.getReviewersByProjectAndRoleId();
  }

  showMoreReviewers(){
    this.getReviewersByProjectAndRoleId(this.pagination.defaultReviewers, this.reviewers.length, 'showMoreReviewersList')
  }
  showMoreAutoGeneratedReviewers(){
    this.getAutoGeneratedReviewersByProjectAndRoleId(this.pagination.defaultReviewers, this.autogeneratedReviewers.length, 'showMoreReviewersList')
  }
  searchShortListedReviewers(): void {
    if (this.searchShortListedDebounce) {
      clearTimeout(this.searchShortListedDebounce);
    }
    this.searchShortListedDebounce = setTimeout(() => {
      this.initializeShortListedReviewer();
    }, 1000);
  }

  searchOfferReviewers(): void {
    if (this.searchOfferDebounce) {
      clearTimeout(this.searchOfferDebounce);
    }
    this.searchOfferDebounce = setTimeout(() => {
      this.initializeOfferQueueReviewers();
    }, 1000);
  }

  getProjectAndRoleId() {
    const params = {
      projectId: this.id,
      roleId: this.selectedRole?._id
    };
    return params;
  }

  async getReviewersByProjectAndRoleId(limit?:number, offset?:number, showMoreFlag?:string): Promise<void> {
    if(!showMoreFlag){
      this.reviewers.length = 0;
      limit = this.pagination.defaultReviewers;
      offset = this.pagination.initialOffset;
    }else{
      limit = this.pagination.defaultReviewers;
      offset = this.reviewers.length;
      this.loadingReviewers = true;
    }
  
    const category = this.selectedFilter ?? null;
    this.updateColumns(category.key);
    this.category = category.key;
    if (this.selectedRole?._id) {
      this.reviewers = this.reviewers.concat(
        (
          await this.reviewerService.getReviewersByProjectId(
            this.id ?? '',
            this.selectedRole._id,
            limit,
            offset,
            this.selectedSortOption[0].key,
            this.selectedPoolIds,
            category?.key || '',
            this.searchValue
          )
        ).reviewers
      );
      this.lastPage = this.reviewers.length < limit;
      const reviewerCount = await this.reviewerService.getReviewersCount(this.id || '', [this.selectedRole?._id || ''], this.selectedPoolIds, category?.key || '', this.searchValue, this.selectedSortOption[0].key);
      this.totalReviewersCount = reviewerCount && reviewerCount.length ? reviewerCount[0].reviewersCount : 0;
      this.loadingReviewers = false;
    } else {
      this.toast.showError('All roles have ended.');
    }
  };

  async getAutoGeneratedReviewersByProjectAndRoleId(limit?:number, offset?:number, showMoreFlag?:string): Promise<void> {
    if(!showMoreFlag){
      this.autogeneratedReviewers.length = 0;
      limit = this.pagination.defaultReviewers;
      offset = this.pagination.initialOffset;
    }else{
      limit = this.pagination.defaultReviewers;
      offset = this.reviewers.length;
      
    }
    this.loadingReviewers = true;
    if (this.selectedRole?._id) {
      this.autogeneratedReviewers = this.autogeneratedReviewers.concat(
        (
          await this.reviewerService.getReviewersByProjectId(this.id ?? '', this.selectedRole?._id, limit, offset, '', [],'', '')
        ).reviewers
      );
      if(this.selectedShortlistedReviewers.length > 0){
      this.autogeneratedReviewers = this.autogeneratedReviewers.filter((autoRev)=>{
        return this.selectedShortlistedReviewers.some((shortRev)=>{
            return shortRev._id !== autoRev._id;
        });
      });    
      }
    } else {
      this.toast.showError('All roles have ended.');
    }
    this.loadingReviewers = false;
  }

  updateColumns(category: string) {
    const validCategory =
      category && Object.keys(this.columnConfigs).includes(category)
        ? category
        : null;

    this.removeConditionalColumns(validCategory || '');

    if (validCategory) {
      this.addConditionalColumns(this.columnConfigs[validCategory]);
    }

    this.updateColsArray();
  }

  removeConditionalColumns(category: string): void {
    const fieldsToRemove: string[] = [];
    if (this.cols.length > 5) {
      fieldsToRemove.push(
        ...(this.columnConfigs[this.category] || []).map((col) => col.field)
      );
    }

    this.cols = this.fixedCols
      .slice(0, 4)
      .concat(
        this.cols
          .slice(4, -1)
          .filter((col) => !fieldsToRemove.includes(col.field))
      )
      .concat(this.fixedCols.slice(-1));
  }

   addConditionalColumns(columnsToAdd: Column[]): void {
    const insertIndex = 4;

    columnsToAdd.forEach((col, index) => {
      if (!this.cols.some(existingCol => existingCol.field === col.field)) {
        this.cols.splice(insertIndex + index, 0, col);
      }
    });
  }

  updateColsArray(): void {
    if (this.cols[this.cols.length - 1]?.field !== 'actions') {
      this.cols.push(this.fixedCols.slice(-1)[0]);
    }
  }

  showMoreInfo($event: Event, details: any){

    const detail =  details.map((detail: any, index: number) => `${index + 1}. Firm Name: ${detail.firmName} -- Role Name: ${detail.roleName} <br>`).join('\n');
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: detail,
      header: 'Reviewer Positions Details:',
      acceptVisible: false,
      rejectVisible: false 
      
    });
  }

  async getShortListedReviewersByProjectAndRoleId(limit = this.pagination.initialReviewers, offset = 0): Promise<void> {
    const category = this.selectedFilter ?? null;
    if(this.selectedRole?._id){
      const response = await this.reviewerService.getShortlistedReviewersByProjectId(this.id || '', this.selectedRole?._id, limit, offset, this.selectedSortOption[0].key, this.selectedPoolIds, category?.key || '', this.searchShortListed, 'shortlistedReviewers');
      this.selectedShortlistedReviewers = this.selectedShortlistedReviewers.concat(response.reviewers);
      this.shortListedReviewersCount = response.count;
      this.getAutoGeneratedReviewersByProjectAndRoleId();
    } else {
      this.toast.showError('All roles have ended.');
    }
    
  }


  async getOfferedQueueReviewers(limit = this.pagination.initialReviewers, offset = 0): Promise<void> {
    const category = this.selectedFilter ?? null;
    const response = await this.reviewerService.getShortlistedReviewersByProjectId(this.id || '', this.selectedRole?._id || '', limit, offset, this.selectedSortOption[0].key, this.selectedPoolIds, category?.key || '', this.searchOfferValue, 'reviewers');
    this.selectedOfferedQueueReviewers = this.selectedOfferedQueueReviewers.concat(response.reviewers);
    this.offersReviewersCount = response.count;
  }

  drop(event: CdkDragDrop<any[]>, from:string) {
    if(from === 'reviewers'){
      moveItemInArray(this.selectedShortlistedReviewers, event.previousIndex, event.currentIndex);
      this.updatePositionOfShortListedReviewers(event.currentIndex, this.selectedShortlistedReviewers[event.currentIndex]._id);  
    } else {
      moveItemInArray(this.autogeneratedReviewers, event.previousIndex, event.currentIndex);
    }
  }

  async onRemoveShortlistedReviewer(reviewer: any): Promise<void>{
    await this.projectService.removeShortListedReviewer(this.id || '', this.selectedRole?.id || '', reviewer._id);
    await this.initializeShortListedReviewer();
    this.selectedReviewers.length = 0;
    await this.getReviewersByProjectAndRoleId();
  }

  async onRemoveOfferedQueueReviewer(reviewer: any): Promise<void>{
    await this.projectService.removeOfferedQueueReviewer(this.id || '', this.selectedRole?.id || '', reviewer._id);
    await this.initializeOfferQueueReviewers();
    await this.initializeShortListedReviewer();
    this.selectedReviewers.length = 0;
    await this.getReviewersByProjectAndRoleId();
  }

  openImmediateSendOfferModal(reviewer: any): void{
    if(!this.validateRoleDetails()){
      return;
    }
    this.sendOfferRef = this.dialogService.open(
      OfferConfirmationModalComponent,
      this.getOfferModalConfig('Send an offer to candidate immediately?', reviewer)
    );

    this.sendOfferRef.onClose.subscribe(async (offer: Offers) => {
      if (offer) {
        const payload = {
          ...offer
        };
        await this.offerService.createOffer(payload);
        this.onInputChange();

      }
    });
  }

  openImmediateOfferQueueSendOfferModal(): void{
    this.sendOfferRef = this.dialogService.open(
      OfferConfirmationModalComponent,
      this.getModalConfig('Do you want to send offers to the next 50 reviewers in the Offer Queue?')
    );

    this.sendOfferRef.onClose.subscribe(async (offer: Offers) => {
      if (offer) {
        const payload = {
          roleId: this.selectedRole?._id,
          positionStarted: offer.positionStarted
        }

        try {
          await this.offerService.createImmediateOffer(payload);
          this.toast.showSuccess('Offers has been sent to the reviewers');
          this.onInputChange();
        } catch (error: any) {
          this.toast.showError(error?.data?.message || 'Error sending offers');
        }
      }
    });
  }

  resumeOffers(): void{
    this.resumeOfferRef = this.dialogService.open(
      OfferConfirmationModalComponent,
      this.getModalConfig('Are you sure you want to resume the Offer Sender? Offers will resume sending at regular intervals.')
    );

    this.resumeOfferRef.onClose.subscribe(async (offer: Offers) => {
      if (offer) {
        const payload = {
          positionStarted: offer.positionStarted
        }
        await this.offerService.resumeOffers(this.id || '', this.selectedRole?._id || '', payload);
        this.disableResumeButton(true);
        this.onInputChange();

      }
    });
  }

  pauseOffers(): void {
    this.pauseOfferRef = this.dialogService.open(
      OfferConfirmationModalComponent,
      this.getModalConfig('Are you sure you want to pause the Offer Sender? New offers will not be sent until Offer Sender is resumed.')
    );

    this.pauseOfferRef.onClose.subscribe(async (offer: Offers) => {
      if (offer) {
        await this.offerService.pauseOffers(this.id || '', this.selectedRole?._id || '');
        this.disableResumeButton(false);
        this.onInputChange();
      }
    });
  }

  async openBulkSendOfferModal(remaining?: boolean): Promise<void>{
    this.sendBulkOfferRef = this.dialogService.open(
      OfferConfirmationModalComponent,
      this.getBulkOfferModalConfig('', remaining)
    );

    this.sendBulkOfferRef.onClose.subscribe(async (offer: Offers) => {
      if (offer) {
        const payload = {
          continueSendingOffers: this.selectedRole?.continueSendingOffers,
          positionStarted: this.selectedRole?.isStarted ? offer.positionStarted : null
        }
        const response = await this.offerService.sendOffers(this.id || '', this.selectedRole?._id || '', payload);
        if(!this.isUnPublishedProject) {
          this.toast.showSuccess(response?.data?.message || 'Selected reviewers are queued to receive offers');
        }
        await this.initializeShortListedReviewer();
        this.onInputChange();
        this.continueSendingOfferCheckBox = false;
      } else {
        this.continueSendingOfferCheckBox = false;
      }
    });
  }
  

  updatePositionOfShortListedReviewers(rank: number, reviewerId: string): void{
    const payload = {
      rank,
      reviewerId
    }
    this.projectService.updateShortlistedPosition(this.id || '', this.selectedRole?._id || '', payload);
  }

  openSendOfferDialog(reviewer: Reviewer): void {
    if(!this.validateRoleDetails()){
      return;
    }
    this.sendOfferRef = this.dialogService.open(
      OfferConfirmationModalComponent,
      this.getOfferModalConfig('Send an offer to candidate immediately?', reviewer)
    );

    this.sendOfferRef.onClose.subscribe(async (offer: Offers) => {
      if (offer) {
        await this.offerService.createOffer(offer);
        this.onInputChange();
      }
    });
  }

  getOfferModalConfig(modalType: string, data?: any) {
    return {
      header: modalType,
      width:  this.selectedRole?.isStarted? '50vw' : '25vw',
      baseZIndex: 10000,
      contentStyle: { overflow: 'visible' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: {
        ...data,
        roleId: this.selectedRole?._id,
        isStarted: this.selectedRole?.isStarted,
        buttonText: modalType,

      },
    };
  }

  getModalConfig(modalType: string) {
    return {
      header: modalType,
      width: '35vw',
      height: '50vh',
      baseZIndex: 10000,
      contentStyle: { overflow: 'visible' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: {
        roleId: this.selectedRole?._id,
        buttonText: modalType
      },
    };
  }

  getBulkOfferModalConfig(modalType: string, remaining?: boolean) {
    const remainingCandidates = remaining? 'remaining': 'shortlisted';
    return {
      header: this.isUnPublishedProject ? 'You have approved the selected team to receive offers after your project is published. Please click OK and proceed to the project review page, make sure everything is correct, and click Publish to get staffed up.' : 'Send offers',
      width: '30vw',
      baseZIndex: 10000,
      contentStyle: { overflow: 'visible' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: {
        roleId: this.selectedRole?._id,
        buttonText: modalType,
        message: `You are about to send offers to ${remainingCandidates} candidates. Are you sure?`
      },
    };
  }

  getTalentPoolModalConfig(modalType: string, data?: any) {
    return {
      header: modalType,
      width: '35vw',
      height: '600px',
      baseZIndex: 10000,
      contentStyle: { overflow: 'visible' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: {
        talentPools: this.talentPools,
        selectedPoolIds: this.selectedPoolIds,
        onChangeTalentPool: (talendPoolIds: Array<string>) => {
          this.selectedPoolIds = talendPoolIds;
          this.getReviewersByProjectAndRoleId();
        },
        buttonText: modalType,
      },
    };
  }

  onFilterChange(category: any) {
    console.log(category);
    this.selectedFilter = category;
    this.getReviewerByFilter(category);
    if (category.key === 'talentPool') {
      this.openTalentPoolSelectionModal();
    }
  }

  getReviewerByFilter(category: any){
    this.getReviewersByProjectAndRoleId();
    
  }

  onSortChange(sortOption: any) {

    this.selectedSortOption.length = 0;
    this.selectedSortOption.push(sortOption);
    this.getReviewersByProjectAndRoleId();
  }

  openTalentPoolSelectionModal() {
    this.talentPoolRef = this.dialogService.open(
      TalentpoolModalComponent,
      this.getTalentPoolModalConfig('Select Talent Pool')
    );

    this.talentPoolRef.onClose.subscribe({
      next: () => {}
    });
  }

  goToPrevious(index: number): void {
    this.changeTab.emit(index);
  }

  gotoSentOfferScreen(index: number){
    this.activeTabIndex = index;
  }

  async updateRole(moveToNextTab: boolean = false): Promise<void> {

    if (this.validateRoleDetails() && this.selectedRole) {
      try {
        await this.projectService.updateProjectRole(this.id || '', this.selectedRole._id, {
          positions: this.selectedRole.positions,
          hourlyRate: this.selectedRole.hourlyRate,
          overTime: this.selectedRole.overTime
        });
  
        if (this.selectedReviewers.length) {
          this.moveToNextScreen(moveToNextTab);
        }
        else if (!moveToNextTab) {
          this.toast.showError(constants.select.error.noReviewerSelected);
        }
        else {
          this.moveToNextScreen(moveToNextTab);
        }
      } catch (error: any) {
        this.toast.showError(error?.data?.message || constants.select.error.updatingRole);
      }
    }
  }

  async moveToNextScreen(moveToNextTab: boolean = false): Promise<void>{
    await this.addToShortListedReviewers();
    if(moveToNextTab){
      await this.initializeShortListedReviewer();
      this.activeTabIndex = 1;
    } else {
      this.getReviewersByProjectAndRoleId();
    }
    
  }

  async selectAndAddReviewerToShortList(reviewer: Reviewer){
    this.selectedReviewers.push(reviewer);
    await this.addToShortListedReviewers();
    await this.ngOnInit();
  }

  async addToShortListedReviewers(){
    await this.projectService.addShortListedReviewerForRole(this.id || '', this.selectedRole?._id || '', {
      reviewers: this.selectedReviewers.map(reviewer => reviewer._id)
    });
    this.selectedReviewers.length = 0;
  }

  validateRoleDetails () {
    if(!this.selectedRole){
      return false;
    }
    if ((this.selectedRole.positions <= this.selectedRole.occupiedPositions) && this.selectedReviewers.length) {
        this.toast.showError(constants.select.error.minimumDesiredFreelancer);
        return false;
    }
    if (this.selectedRole?.hourlyRateSaved <= 0) {
        this.toast.showError(constants.select.error.minimumHourlyRate);
        return false;
    }
    return true;
  }

  async onReviewerTabChange(e: any): Promise<void>{
    switch (this.activeTabIndex) {
      case 0:
        this.getReviewersByProjectAndRoleId();
        break;
      case 1:
        this.onInputChange();
        break
      default:
        break;
    }
  }

  async initializeShortListedReviewer(): Promise<void>{
    this.selectedShortlistedReviewers.length = 0;
    this.getShortListedReviewersByProjectAndRoleId();
  }

  async initializeOfferQueueReviewers(): Promise<void>{
    this.selectedOfferedQueueReviewers.length = 0;
    await this.getOfferedQueueReviewers();
  }

  async getRoleByProjectAndRoleID(): Promise<void>{
    if(!this.selectedRole){
      return;
    }
    this.updatedRole = await this.rolesService.getRoleByProjectAndRoleId(this.id || '', this.selectedRole?._id || '');
    this.selectedRole.offersQueueId = this.updatedRole?.offersQueueId;
  }

  disableResumeButton(isQueueRunning: boolean): void{
    if(!this.selectedRole || !this.updatedRole){
      return;
    }
    this.selectedRole.offersQueueId = isQueueRunning;
    this.updatedRole.offersQueueId = isQueueRunning;
    this.showResumeButton = !isQueueRunning; 
  }

  initializeResumeButton(): void{
    this.showResumeButton = !!(this.offersReviewersCount && !this.updatedRole?.offersQueueId &&
      (this.project?.status === constants.roleStatus.published ||
          this.project?.status === constants.roleStatus.started) &&
      ((this.updatedRole?.occupiedPositions || 0) < (this.updatedRole?.positions || 0)));
  }

  openReviewerDialog(id:string){
    this.reviewerDetailDialogService.openDialog(id);
  }

  selectRoleForOffer(role: any){
    this.selectedRole = this.roles.find((r:any) => r._id === role._id) || null;    
  }
}
