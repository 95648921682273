<div class="grid grid-nogutter mb-3">
    <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
        <h1 class="h4 m-0">Firm Users</h1>
        <div class="breadcrumb">
            <p-breadcrumb 
                class="max-w-full" 
                [model]="items" />
        </div>
    </div>
    <div class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center  md:gap-4 gap-0 ">
        <p-button label="CREATE" [rounded]="true" icon="pi pi-plus" size="large" (onClick)="showCreateFirmUserModal()"/>
    </div>
</div>
<div class="projects-data">
    <div class="grid grid-nogutter">
        <div class="col-12 p-0">
            <div class="grid grid-nogutter">
                <div class="col-12 md:col-6 xl:col-4 lg:col-6 sm:col-12 p-0">
                    <div class="wrap-left">
                       <div class="grid grid-nogutter">
                            <div class="md:pr-1 sm:pr-0 col-12 md:col-8 sm:col-12 ">
                                <p-iconField iconPosition="right">
                                    <p-inputIcon styleClass="pi pi-search" />
                                    <input [(ngModel)]="searchText" (ngModelChange)="onSearch()" type="text" pInputText placeholder="Search Keywords" />
                                </p-iconField>
                            </div>
                            <div class="p-0 col-12 md:col-12 sm:col-12">
                                <ul class="chips">
                                    @if(searchText){
                                        <li><p-chip [label]="searchText" (onRemove)="onRemoveChip('searchText')" [removable]="true" /></li>
                                    }
                                </ul>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="firmUsers" [paginator]="true" [rows]="10" [tableStyle]="{ 'min-width': '50rem' }"
                [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                autoLayout="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="code">
                            First Name <p-sortIcon field="code" />
                        </th>
                        <th pSortableColumn="name">
                            Last Name <p-sortIcon field="name" />
                        </th>
                        <th pSortableColumn="date">
                            Email <p-sortIcon field="date" />
                        </th>
                        <th pSortableColumn="type">
                            Type
                        </th>
                        <th pSortableColumn="status">
                            Status
                        </th>
                        <th style="width:8%">
                            Actions
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-firmUsers>
                    <tr>
                        <td>{{ firmUsers.firstName }}</td>
                        <td>{{ firmUsers.lastName }}</td>
                        <td>{{ firmUsers.email }}</td>
                        <td>{{ getRole(firmUsers.role) }}</td>
                        <td>
                            @if(firmUsers.isActive){
                                <p-tag value="Active" [rounded]="true" class="pillActive"/>
                            }
                            @else {
                            <p-tag value="Inactive" [rounded]="true" class="pillInactive" />
                            }
                        </td>
                        <td>
                            <div class="wrap-actions flex align-items-center gap-2">
                                <p-button pTooltip="Edit" 
                                tooltipPosition="left" [rounded]="true" [text]="true" icon="true" (onClick)="editFirmUser(firmUsers)">
                                    <span class="material-symbols-outlined">
                                        edit
                                    </span>
                                </p-button>
                                @if(firmUsers.status === 'PENDING_EMAIL_VERIFICATION'){
                                    <p-button pTooltip="Resend Invite" 
                                    tooltipPosition="left" [rounded]="true" [text]="true" icon="true" (onClick)="resendReinviteEmail($event, firmUsers)">
                                        <span class="material-symbols-outlined">
                                            send
                                        </span>
                                    </p-button>
                                }
                                @if(firmUsers.status === 'VERIFIED'){
                                    <p-button pTooltip="Send Password Reset Link" 
                                    tooltipPosition="left" [rounded]="true" [text]="true" icon="true" (onClick)="resetPassword(firmUsers.email)">
                                        <span class="material-symbols-outlined">
                                            lock_reset
                                        </span>
                                    </p-button>
                                }
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-dialog 
    header="Group Message" 
    [(visible)]="visible" 
    [modal]="true" 
    [breakpoints]="{'575px': '90vw' }" 
    [style]="{ width: '660px' }" 
    [draggable]="false" 
    [resizable]="false">
    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                {{modalTitle}}
            </span>
        </div>
    </ng-template>
    <form [formGroup]="createForm">
        <div class="grid p-0">
            <div class="col-12 lg:col-6 xl:lg:col-6 pb-3 pt-0 pl-0 pr-0 xl:pr-2">
                <p-floatLabel>
                    <input formControlName="firstName" type="text" pInputText />
                    <label for="username">First Name</label>
                </p-floatLabel>
            </div>
            <div class="col-12 lg:col-6 xl:lg:col-6 pb-3 pt-0 pr-0 pl-0 xl:pl-2">
                <p-floatLabel>
                    <input formControlName="lastName" type="text" pInputText />
                    <label for="username">Last Name</label>
                </p-floatLabel>
            </div>
            <div class="col-12 lg:col-6 xl:lg:col-6 pb-3 pt-0 pl-0 pr-0 xl:pr-2">
                <p-floatLabel>
                    <input formControlName="email" type="email" pInputText />
                    <label for="username">Email</label>
                </p-floatLabel>
            </div>
            <div class="col-12 lg:col-6 xl:lg:col-6 pb-3 pt-0 pr-0 pl-0 xl:pl-2">
                <p-floatLabel>
                    <input formControlName="phone" type="tel" pInputText />
                    <label for="username">Phone</label>
                </p-floatLabel>
            </div>
            <div class="col-12 p-0">
                <p class="m-0 text-sm text-black-alpha">Select A Role</p>
            </div>
            <div class="col-12">
                <div class="flex flex-wrap gap-3">
                    <div class="flex align-items-center">
                        <p-radioButton 
                            formControlName="role" 
                            value="FIRM_MANAGER" />
                        <label for="firmManager" class="ml-2">
                            Firm Manager
                        </label>
                    </div>
                    
                    <div class="flex align-items-center">
                        <p-radioButton 
                            formControlName="role" 
                            value="PROJECT_OWNER" />
                        <label for="projectOwner" class="ml-2">
                            Project Manager
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        @if(isEdit) {
            <p-button label="{{statusButtonLabel}}" size="large" [rounded]="true" [outlined]="true" (onClick)="onStatusChange()"/>
        } @else {
            <p-button label="{{cancelButtonlabel}}" size="large" [rounded]="true" [outlined]="true" (onClick)="hideDialog()"/>
        }
        <p-button styleClass="freshgreen-background" label="{{buttonlabel}}" size="large" [disabled]="!createForm.valid" [rounded]="true" (onClick)="onSubmit()"/>
    </ng-template>
</p-dialog>
