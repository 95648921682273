@if(config.data.conflictQuestions.length > 0){
    <div header="Screening Questions" class="align-items-center">
        <div class="col-12 mb-3 p-0">
            <p><b>Please answer these Screening Questions to proceed:</b></p>
        </div>
        <div class="col-12 mb-3 p-0">
            <p>{{config.data.conflictQuestions[currentIndex].title}}</p>
        </div>
       
        <div class="flex justify-content-end gap-2">
            <p-button label="YES" size="large" [rounded]="true" styleClass="freshgreen-background" (click)="selectAnswer(true)"  />
            <p-button label="NO" size="large" [rounded]="true" (click)="selectAnswer(false)" severity="danger"/>
        </div>
    </div>
}
