import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter';
import { LineBreakPipe } from './line-break';

@NgModule({
  declarations: [FilterPipe, LineBreakPipe],
  imports: [
    CommonModule
  ],
  exports: [FilterPipe, LineBreakPipe]
})
export class PipesModule { }