<app-heading
  [title]="'Credentials'"
  [button]="headingButton"
  [isbreadcrumb]="true"
  [showToggle]="false"
></app-heading>

@for(creds of reviewerCredentials; track creds){

<p-accordion [multiple]="true" [activeIndex]="[0]" class="w-full" *ngIf="true">
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center justify-content-between gap-4 w-full">
        <div class="flex flex-column line-height-2">
          <span class="white-space-nowrap">
            {{ creds.name }}
          </span>
        </div>
        <div class="flex flex-column line-height-2">
          <span class="font-medium white-space-nowrap"> Issue Date </span>
          <span>
            {{ creds.issuedAt || "-" | date : "MM/dd/yyyy" }}
          </span>
        </div>
        @if(creds.autoRenewal){
          <div class="flex flex-column line-height-2">
            <span class="font-medium white-space-nowrap"> Expiration Date </span>
            @if(creds.expiresAt){
              <span>
                {{ creds.expiresAt | date : "MM/dd/yyyy" }}
              </span>
            } @else {
              <span>
                -
              </span>
            }
          </div>
        } @else {
          <div [hidden]="true" class="flex flex-column line-height-2"></div>
        }
        <div class="mr-4 flex">
          <p-button
            [rounded]="true"
            [text]="true"
            icon="true"
            (onClick)="editMode($event, creds)"
          >
            <span class="material-symbols-outlined"> edit </span>
          </p-button>
          <p-button
            [rounded]="true"
            [text]="true"
            icon="true"
            (onClick)="onDelete($event, creds)"
          >
            <span class="red material-symbols-outlined"> delete </span>
          </p-button>
        </div>
      </div>
    </ng-template>
    <div class="flex flex-column gap-3">
      <div class="grid">
        @if(creds.secondaryOptionsArray) {

        <div class="col-12 flex flex-column gap-1">
          <span class="text-xs text-primary-900 font-medium"
            >Secondary Options</span
          >
          <div class="flex align-items-center gap-2 flex-wrap">
            @for( option of creds.secondaryOptionsArray; track option) {
            <p-chip label="{{ option }}" [removable]="false" />

            }
          </div>
        </div>
        } 
        @if(creds.certificate){
        <div class="col-12 flex flex-column gap-1">
          <span class="text-xs text-primary-900 font-medium">Document</span>
          <div class="flex align-items-center gap-2 flex-wrap">
            <a href="{{ creds.certificate.downloadURL }}" target="_self">
              <p-chip label="Certificate.PDF" [removable]="false" />
            </a>
          </div>
        </div>
        }
      </div>
    </div>
  </p-accordionTab>
  <!-- <p-accordionTab>
        <ng-template pTemplate="header">
            <div class="flex align-items-center justify-content-between gap-4 w-full">
                <div class="flex flex-column line-height-2">
                    <span class="white-space-nowrap">
                        Amy Elsner
                    </span>
                </div>
                <div class="flex flex-column line-height-2">
                    <span class="font-medium white-space-nowrap">
                        Issue Date
                    </span>
                    <span>
                        12/05/2022
                    </span>
                </div>
                <div class="flex flex-column line-height-2">
                    <span class="font-medium white-space-nowrap">
                        Expiration Date
                    </span>
                    <span>
                        12/05/2022
                    </span>
                </div>
                <div>
                    <p-tag value="Verified" [rounded]="true" />
                </div>
                <div class="mr-4 flex">
                    <p-button [rounded]="true" [text]="true" icon="true" (onClick)="editMode($event)">
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </p-button>
                    <p-button [rounded]="true" [text]="true" icon="true" (onClick)="editMode($event)">
                        <span class="material-symbols-outlined">
                            delete
                        </span>
                    </p-button>
                    <p-button [rounded]="true" [text]="true" icon="true" (onClick)="editMode($event)">
                        <span class="material-symbols-outlined">
                            download
                        </span>
                    </p-button>
                </div>
            </div>
        </ng-template>
        <div class="flex flex-column justify-content-between gap-3">
            <div class="grid w-full">
                <div class="col-12">
                    <p-floatLabel>
                        <p-dropdown [options]="dummy" optionLabel="name" inputId="selectCredentials"
                            placeholder="Select Credentials" />
                        <label for="selectCredentials">Select Credentials</label>
                    </p-floatLabel>
                </div>
                <div class="lg:col-6 col-12">
                    <p-floatLabel>
                        <p-dropdown [options]="dummy" optionLabel="name" inputId="issueDate" placeholder="Issue Date" />
                        <label for="issueDate">Issue Date</label>
                    </p-floatLabel>
                </div>
                <div class="lg:col-6 col-12">
                    <p-floatLabel>
                        <p-dropdown [options]="dummy" optionLabel="name" inputId="expirationDate"
                            placeholder="Expiration Date" />
                        <label for="expirationDate">Expiration Date</label>
                    </p-floatLabel>
                </div>
                <div class="col-12">
                    <p-floatLabel>
                        <p-dropdown [options]="dummy" optionLabel="name" inputId="graduationYear"
                            placeholder="Graduation Year" />
                        <label for="graduationYear">Graduation Year</label>
                    </p-floatLabel>
                </div>
                <div class="col-12">
                    <p-floatLabel>
                        <p-multiSelect [options]="dummy" optionLabel="name" inputId="secondaryOptions"
                            placeholder="Secondary Options" />
                        <label for="secondaryOptions">Secondary Options</label>
                    </p-floatLabel>
                    <div class="flex align-items-center gap-2 flex-wrap pt-3">
                        <p-chip label="NCSP Foundation Certtificate Training" [removable]="true" />
                        <p-chip label="NCSP Foundation Certtificate Training" [removable]="true" />
                        <p-chip label="NCSP Foundation Certtificate Training" [removable]="true" />
                        <p-chip label="NCSP Foundation Certtificate Training" [removable]="true" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="flex flex-column gap-2">
                        <span class="text-sm text-black-alpha-60">Select .pdf within 50 MB:</span>
                        <p-fileUpload mode="basic" chooseLabel="Upload certificates" chooseIcon="pi pi-plus"
                            styleClass="border-round-3xl" name="demo[]"
                            url="https://www.primefaces.org/cdn/api/upload.php" accept="pdf/*" maxFileSize="1000000"
                            (onUpload)="onUpload($event)" />
                    </div>
                </div>
            </div>
            <div class="flex gap-3">
                <p-button label="Discard" rounded="true" outlined="true" size="large"
                    styleClass="white-space-nowrap"></p-button>
                <p-button label="Save" rounded="true" size="large" styleClass="white-space-nowrap"></p-button>
            </div>
        </div>
    </p-accordionTab> -->
</p-accordion>
}
