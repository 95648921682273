import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Pipe({name: 'lineBreak'})

export class LineBreakPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    transform(input: any): SafeHtml {
        if (!input){ 
            return input;
        }
        const formatted = input
        .replaceAll('<', '')
        .replaceAll('>', '')
        .replace(/(\r\n|\n|\r)/g, '<br/>');
        return this.sanitizer.bypassSecurityTrustHtml(formatted);
    }
}
