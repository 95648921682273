import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Table, TableLazyLoadEvent, TableModule, TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { DocumentService } from '../../../../../../shared/services/document-service';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { ConfirmationDialogService } from '../../../../../../shared/services/confirmation-dialog.service';
import { UserClientVm } from '../../../../../../store/user/user.interface';
import { UserService } from '../../../../../../shared/services/user.service';
import { constants } from '../../../../../../shared/constants/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { Firm } from '../../../../../../shared/interfaces/firm.interface';
import { Project } from '../../project.interface';
import { Documents, TemplateFields } from '../../../../../../shared/interfaces/document.interface';
import { ProjectNavigationService } from '../../../../../../shared/services/project-navigation.service';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { EditTemplateService } from './edit-template/edit-template.service';


@Component({
  selector: 'app-project-documents',
  standalone: true,
  imports: [
    DragDropModule,
    ChipModule,
    DialogModule,
    TableModule,
    TabViewModule,
    InputIconModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    IconFieldModule,
    InputTextareaModule,
    FormsModule,
    RadioButtonModule,
    FloatLabelModule,
    InputTextModule,
    InputSwitchModule,
    MultiSelectModule,
    ReactiveFormsModule,
    CommonModule,
    EditTemplateComponent
  ],
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss'
})
export class ProjectDocumentsComponent implements OnInit, OnChanges {

  @Input()
  project: Project | null = null;
  @Output() 
  updateProjectState: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  changeTab: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('documentTable') table: Table | undefined;

  selectTemplateType: string = 'Existing';
  checked: boolean = false;
  expandedRows = {};
  documents: any = [];
  totalDocuSignTemplate = 0;
  docuSearchText = '';
  searchOfferDebounce: any = null;
  templateDocuments: any = [];
  user!: UserClientVm;
  visible: boolean = false;
  visibleResumeDialog: boolean = false;
  showAppRole = false;
  pdfSource: any;
  selectedDocuments: any = [];
  selectedDocSignTemplate: any;
  projectId!: string | null;
  projectFiles: any = [];
  fileId: any;
  docTitleRequired = false;
  templateName: any = '';
  docTitle: any;
  pdfDocuments: any = [];
  projectResumeFile: any = null; 
  firm: Firm | null = null;
  fileName = 'No file selected';
  reviewDocuments: Array<Documents> = [];

  resumeDocumentForm!: FormGroup;

  isEditResumeDocument = false;
  docuSignEditorOpen = false;
  isEditMode = false;
  selectedResumeDocument: Documents | null = null;
  visibleDocuSignConfirmModal = false;
  visibleMultiDocuSignConfirmModal = false;
  docAdded = false;
  addToTemplate = false;
  permanentAddconfirmVisible = false;
  documentMetadata: any = null; 
  compositeDocuSignTemplateKey = '';

  isSaveTemplateInProgress = false;

  constructor(private route: ActivatedRoute, private projectService: ProjectService, 
    private documentService: DocumentService, private editTemplateService: EditTemplateService, private confirmationDialogService: ConfirmationDialogService, 
    private userService: UserService, private toastService: ToastService, private fb: FormBuilder, 
    private projectNavigation: ProjectNavigationService, private router: Router) {}

  ngOnInit() { 
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    this.user = this.userService.getSessionUser();
    this.firm = this.user.firm;
    this.initializeForm();
    this.initializeList();
  }

  updateProject(): void{
    this.updateProjectState.emit();
  }

  initializeList(): void{
    this.getProjectInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.project){
      this.initializeList();
      this.getDocumentsByIds();
    }
  }

  getProjectInfo(){
    this.compositeDocuSignTemplateKey = (this.project?.docuSignTemplates || ['']).map((doc) => {
      return doc;
    }).join('_');
  }

  getDocumentsByIds(): void {
    if(this.project && this.project.documents && this.project.documents.length){
      const commaSeparatedDocIds =  this.project.documents.map((doc: any) => doc._id).join(',');
      this.documentService.getDocumentsByIds(commaSeparatedDocIds).subscribe({
        next: (response) => {
          this.reviewDocuments.length = 0;
          response.forEach((item: Documents) => {
            this.reviewDocuments.push(item);
          });
        }
      }); 
    }
  }

  getDocumentAndTemplate(){
    const documentTemplate: Array<any> = this.selectedDocuments.filter((template: any) => template.documents && template.documents.length).map((template: any) => {
      return {templateId: template.templateId, templateName: template.name, documents: template.documents, _id:template._id.toString()}
    });

    return documentTemplate;
  }

  getRadioGroupField(documentIdIndex: number, pageId: any, fields: any, fieldAt: any, documentFields: any ) {
    const selectedRadioObject = fields[fieldAt]; 

    if(!selectedRadioObject) {
      return selectedRadioObject;
    }
    selectedRadioObject.documentId = documentIdIndex+1;
    selectedRadioObject.pageNumber = pageId;
    selectedRadioObject.radioGroupTabs = [];
    
    const selectedRadioGroupValues = Object.values(selectedRadioObject.radioGroup);
    if(selectedRadioObject.top === undefined || selectedRadioObject.left === undefined ) {
      selectedRadioObject.top  = constants.defaultDocuSignFieldsCoordinates['radioTab'].top;
      selectedRadioObject.left  = constants.defaultDocuSignFieldsCoordinates['radioTab'].left;
    }
    selectedRadioGroupValues.map((radio: any, index: number) => {
      const radioTabOption = documentFields[pageId]['radioTab'][radio.id];
      radio.tabName = radioTabOption ? radioTabOption.tabName:'';
      radio.selected = !!radio.selected;
      radio.documentId = documentIdIndex+1;
      radio.pageNumber = pageId;
      if(selectedRadioObject.alignHorizontal) {
        radio.xPosition = selectedRadioObject.left+ ((index+1)*28 -11);
        radio.yPosition = selectedRadioObject.top +15;
      } else {
        radio.xPosition = selectedRadioObject.left;
        radio.yPosition = selectedRadioObject.top + ((index+1)*28 -11);
      }
      selectedRadioObject.radioGroupTabs.push(radio);
    }); 

    delete selectedRadioObject.radioGroup;
    delete selectedRadioObject['$$hashKey'];
    
    return selectedRadioObject;
  }

  getXPositionOfFieldType(fieldValue: any, fieldType: string) {
    if(fieldValue === undefined) {
        const defaultDocuSignFieldsCoordinates: any = constants.defaultDocuSignFieldsCoordinates;
        const defaultFieldValue = defaultDocuSignFieldsCoordinates[fieldType];
        return defaultFieldValue.left;
      }

      return fieldValue;
  }

  getYPositionFieldType(fieldValue: any, fieldType: string) {
      if(fieldValue === undefined) {
        const defaultDocuSignFieldsCoordinates: any = constants.defaultDocuSignFieldsCoordinates;
        const defaultFieldValue = defaultDocuSignFieldsCoordinates[fieldType];
        return defaultFieldValue.top;
      }

      return fieldValue;
  }

  goToPrevious(index: number): void {
    this.changeTab.emit(index);
  }

  async next(requestState: string): Promise<void> {
    if(this.firm?.isDocusignEnabled){
      this.onDocuSignEnableNext(requestState);
    } else {
      this.onDocuSignDisableNext(requestState);
    }
  }

  updateTemplateDocument(): void{
    const templateDocuments = this.editTemplateService.getDocumentInfoByKey('uploadedFiles');
    this.templateDocuments = templateDocuments && templateDocuments.length ? templateDocuments : this.templateDocuments;
  }

  async onDocuSignEnableNext(requestState: string): Promise<void>{
    const pId = this.projectId || '';
    const firmId = this.firm?._id;

    if (this.selectTemplateType === 'New') {
      this.isSaveTemplateInProgress = true;
      if (!this.templateName) {
        this.toastService.showError('Required fields are missing');
        return;
      }

      const templateDocuments = this.templateDocuments;
      const templateFields: any = {};
      templateDocuments.forEach((document: any, index: number) => {
        for (const page in document.templateFields) {
          const fieldTypes: Array<TemplateFields> = document.templateFields[page];
          for (const fieldType in fieldTypes) {
            if (!Array.isArray(templateFields[fieldType])) {
              templateFields[fieldType] = [];
            }
            const fields: any = Object.values(document.templateFields[page][fieldType]);
            for (let fieldI = 0; fieldI < fields.length; fieldI++) {
              if (fieldType === 'radioTab' && fields.length > 0) {
                const isDomIdExist: any = fields[fieldI];
                if (isDomIdExist && isDomIdExist.domid) {
                  const radioFieldObject = this.getRadioGroupField(index, page, fields, fieldI, document.templateFields);
                  templateFields[fieldType].push(radioFieldObject);
                }
              } else if (fields[fieldI] && Object.values(fields[fieldI]).length) {
                templateFields[fieldType].push({
                  xPosition: Math.round(this.getXPositionOfFieldType(fields[fieldI].left, fieldType)),
                  yPosition: Math.round(this.getYPositionFieldType(fields[fieldI].top, fieldType)),
                  documentId: index + 1,
                  pageNumber: page,
                  groupId: fields[fieldI]?.groupId,
                  tabGroupLabel: fields[fieldI]?.tabGroupLabel,
                  tabLabel: fields[fieldI].tabLabel,
                  groupTooltip: fields[fieldI].groupTooltip,
                  validationType: fields[fieldI].validationType,
                  validationCount: fields[fieldI].validationCount,
                  validationCountMin: fields[fieldI].validationCountMin,
                  validationCountMax: fields[fieldI].validationCountMax,
                  conditionalFields: fields[fieldI].conditionalFields ? fields[fieldI].conditionalFields.map((condition: any) => ({
                    selectedElement: condition.selectedElement,
                    checked: condition.checked
                  })) : null,
                  height: fields[fieldI].height,
                  width: fields[fieldI].width,
                  editable: fields[fieldI].editable,
                  tooltip: fields[fieldI].tabName,
                  tabName: fields[fieldI].tabName,
                  required: fields[fieldI].required
                });
              }
            }
          }
        }
      });

      if (templateDocuments.length === 0) {
        this.toastService.showError('Please upload a document');
        return;
      }

      const documents = templateDocuments.map((doc: any) => doc.file);
      const fileOptions = templateDocuments.map((doc: any) => ({
        projectId: pId,
        name: doc.name,
        docTitle: doc.docTitle
      }));

      this.projectService.createProjectTemplate(pId, firmId || '', documents, fileOptions, this.templateName, this.getTemplateFieldsOrDefault(templateFields))
        .subscribe({
          next: (response: any) => {
            this.isSaveTemplateInProgress = false;
            this.reloadTableData();
            this.selectTemplateType = 'Existing';
            this.projectFiles = [];
            this.docTitle = '';
            this.templateDocuments = [];
            this.editTemplateService.setDocumentInfoByKey('uploadedFiles', [])
          },
          error: (error: any) => {
            this.onFailureUpsertingTemplate(error);
          }
        });
    } else {
      const documentTemplate = this.getDocumentAndTemplate();
      const compositeDocuSignKey = documentTemplate.map(temp => temp._id).join('_');
      const projectStep = Number(this.project?.step || 0);

      if (this.docAdded) {
        this.addTemplateDocumentConfirmation(projectStep, pId, firmId || '', documentTemplate, null);
      } else {
        this.multipleTemplateSelected(compositeDocuSignKey, projectStep, pId, firmId || '', documentTemplate, null);
      }
    }
  }

  removeDocument(index: number){
    this.templateDocuments.splice(index, 1);
  }

  addTemplateDocumentConfirmation(step: number, pId: string, firmId: string, documentTemplate: any, requestState: any){
    this.documentMetadata = {
      step, 
      pId, 
      firmId, 
      documentTemplate, 
      requestState
    };
    this.visibleDocuSignConfirmModal = true;
  }

  openDocuSignEditor(docuSignEditorOpen = false): void{
    this.docuSignEditorOpen = docuSignEditorOpen;
  }

  multipleTemplateSelected(compositeDocuSignKey: any, step: number, pId: string, firmId: string, documentTemplate: any, requestState: any){
    this.documentMetadata = {
      compositeDocuSignKey,
      step, 
      pId, 
      firmId, 
      documentTemplate, 
      requestState
    };
    const projectStep = Number(this.project?.step || 0);

    if(compositeDocuSignKey !== this.compositeDocuSignTemplateKey && projectStep === 7){
      this.visibleMultiDocuSignConfirmModal = true; 
    } else {
      this.updateProjectTemplate(step, pId, documentTemplate, firmId, requestState);
    }
  }

  setDocReqForStaff(confirm = false){
    this.visibleDocuSignConfirmModal = false;
    const {step, pId, documentTemplate, firmId, requestState} = this.documentMetadata;
    
    if(confirm){
      if(this.addToTemplate){
        this.createAdditionalProjectTemplate(step, pId, documentTemplate, firmId, requestState);
      }
      else{
        this.updateProjectTemplate(step, pId, documentTemplate, firmId, requestState);
      }
      this.toastService.showSuccess("Document(s) have been sent to existing users");
    }
  }

  async createAdditionalProjectTemplate(step: number, pId: string, documentTemplate: any, firmId: string, requestState: any) {
    
    try {
      await this.documentService.addAdditionalProjectTemplateDocuments(pId, firmId, documentTemplate);
      this.onCreatingOrUpdatingTemplateSuccessfully(step, pId, requestState);
      this.projectFiles = [];
      this.docTitle = '';
      this.addToTemplate = false;
    } catch (error) {
      this.onFailureUpsertingTemplate(error);
    }
  }

  async updateProjectTemplate(step: number, pId: string, documentTemplate: any, firmId: string, requestState: any){
    try {
      this.isSaveTemplateInProgress = true;
      await this.documentService.updateProjectDocuSignTemplate(pId, firmId, documentTemplate);
      this.onCreatingOrUpdatingTemplateSuccessfully(step, pId, requestState);
      this.projectFiles = [];
      this.docTitle = '';
      this.isSaveTemplateInProgress = false;
    } catch (error) {
      this.isSaveTemplateInProgress = false;
      this.onFailureUpsertingTemplate(error);
    }
  }

  async onCreatingOrUpdatingTemplateSuccessfully(step: number, pId: string, requestState: string) {
    const projectStep = Number(this.project?.step || 0);
    if (projectStep < step) {
      const response = await this.projectService.updateProjectStep(pId, step)
      if (response.status === 200) {
        if (requestState === "review") {
          this.changeTab.emit(4);
        } else {
          this.changeTab.emit(2);
        }
      }
    } else {
      this.goToNextState(requestState);
    }
  }

  goToNextState(state: string) {
    const requestState = state || 'next';
    if (requestState === 'next') {
        this.changeTab.emit(2);
    } else if (requestState === 'review') {
        this.changeTab.emit(4);
    } else if (requestState === 'save') {
        this.goToProjectListing();
    }
  }
  

  setMultiDocReqForStaff(confirm = false){
    this.visibleMultiDocuSignConfirmModal = false;
    
    const {step, pId, documentTemplate, firmId, requestState} = this.documentMetadata;

    if(confirm){
      try {
        this.updateProjectTemplate(step, pId, documentTemplate, firmId, requestState);
        this.toastService.showSuccess("Document(s) have been sent to existing users");
      } catch (error) {
        this.toastService.showSuccess("Document(s) have been saved and will be sent to new users");
      }
    }
  }

  uploadPermanentDocs(confirm = false){
    this.permanentAddconfirmVisible = false;
    if(confirm){
      this.updateInExistingTemplate({file: this.templateDocuments, title: this.docTitle});
    }
  }

  onFailureUpsertingTemplate(error: any){
    if (error?.data?.message || error?.message) {
        this.toastService.showError((error?.data?.message || error?.message) || "Error while adding credentials.");
    } else if (error?.statusText && error?.statusText === 'Request Entity Too Large') {
        this.toastService.showError('File is too large');
    }
  }

  async onDocuSignDisableNext(requestState: string): Promise<void>{
    const projectStep = Number(this.project?.step || 0);
    const step = 5;
    if (projectStep < step) {
        this.projectNavigation.setCurrentStep(step);
        await this.projectService.updateProjectStep(this.projectId || '', step);
        if (requestState === 'review') {
            this.changeTab.emit(4);
        } else {
          this.changeTab.emit(2);
        }
    } else {
        this.projectNavigation.setCurrentStep(projectStep);
        this.goToNextStep(requestState);
    }
  }

  goToNextStep(requestState: string) {
    if (requestState === 'next') {
      this.changeTab.emit(3);
    }
    else if (requestState === 'review') {
      this.changeTab.emit(4);
    }
    else if (requestState === 'save') {
        this.goToProjectListing();
    }
  }

  reloadTableData(): void{
    if (this.table) {
      const event: TableLazyLoadEvent = {
        first: this.table.first || 1,
        rows: this.table.rows
      };
      this.getDocuSignTemplates(event);
    }
  }

  editTemplateDocumentView(templateId: string, documentId: string, documentName: string, docuSignTemplate: any){
    this.documentService.getDocuSignDocument(templateId, documentId).subscribe({
      next: async (res) => {
        const url = URL.createObjectURL(new Blob([res]));
        const response = await fetch(url);
        const blob = await response.blob();
        const pdfContent = await this.getFileReaderObject(blob);
        
        const docIndex =  docuSignTemplate.documents.findIndex((doc: any) => {
          return doc.name === documentName;
        });
        const documentUri = pdfContent;;
        this.editTemplateService.setDocumentInfoByKey('editTemplate',
          { 
            documentId: docIndex+1, 
            documentUri,
            docuSignTemplate 
          }
        );
        this.isEditMode = true;
        this.docuSignEditorOpen = true;
      },
      error: (error) => {
      }
    })
  }

  viewDocumentForTemplate(document: any) {
    this.editTemplateService.setDocumentInfoByKey('editTemplate', null);
    this.editTemplateService.setDocumentInfoByKey('uploadedFiles', this.templateDocuments);
    this.editTemplateService.setDocumentInfoByKey('file',document.file.length?document.file[0]:document.file);
    this.editTemplateService.setDocumentInfoByKey('templateName', this.templateName);
    this.isEditMode = false;
    this.docuSignEditorOpen = true;
  }

  getFileReaderObject(blob: any): Promise<any>{
    const reader = new FileReader();
    
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(new Error('Failed to read the blob content'));
      };
      
      reader.readAsText(blob);
    });
  }

  initializeForm(): void{
    if(!this.firm?.isDocusignEnabled){
      this.resumeDocumentForm = this.fb.group({
        docTitle: ['', [Validators.required]],
        instructions: ['']
      })
    }
  }

  get resumeForm(): { [key: string]: AbstractControl } {
    return this.resumeDocumentForm.controls;
  }

  onLazyLoad(event: TableLazyLoadEvent ){
    this.getDocuSignTemplates(event);
  }

  getDocuSignTemplates(event: TableLazyLoadEvent | null = null){
    const offset = event?.first || 0;
    const size = event?.rows || 10;
    this.documentService.getDocuSignTemplates(this.projectId!, this.docuSearchText, size, offset).subscribe({
      next: (documents) => {
        this.documents = documents.envelopeTemplates;
        
        this.totalDocuSignTemplate = documents.total;
        this.selectedDocuments = this.documents.filter((document: any) => document.selected);
        if(this.templateName){
          const document = this.documents.find((doc: any) => doc.name === this.templateName);
          this.templateName = '';
          this.selectedDocuments.push(document);
        }
      }
    });
  }

  searchDocuTemplate(): void{
    if (this.searchOfferDebounce) {
      clearTimeout(this.searchOfferDebounce);
    }
    this.searchOfferDebounce = setTimeout(() => {
      this.getDocuSignTemplates();
    }, 1000);
  }

  addDocumentInTemplate($event:Event, docSignTemplate: any) {
    if(docSignTemplate.createdBy !== this.user._id){
      this.toastService.showError('You are not authorized to add document in this template');
      return;
    }
    this.selectedDocSignTemplate = docSignTemplate;
    this.showDialog();
  }

  viewDocument(templateId: string, documentId: string, documentName: string) {

    this.documentService.getDocuSignDocument(templateId, documentId).subscribe(async (res) => {
      const url = URL.createObjectURL(new Blob([res]));
      const response = await fetch(url);
      const blob = await response.blob();
      const pdfContent = await this.documentService.getFileReaderObject(blob);

      const uri = `data:application/octet-stream,${window.escape(pdfContent)}` ;
      this.pdfSource = {url: uri};
      let downloadLink = document.createElement("a");
      downloadLink.href = uri;
      downloadLink.download = documentName.indexOf(".pdf")>-1?documentName:documentName+".pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  deleteTemplateDocument($event:Event, documentId: string, templateId: string, docSignTemplate: any) {
    if(docSignTemplate.createdBy !== this.user._id){
      this.toastService.showError('You are not authorized to delete this template');
      return;
    }
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: 'Are you sure you want to delete this ' + (docSignTemplate.documents.length === 1 ? 'template?': 'document?'),
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
          this.documentService.deleteDocuSignDocument(documentId, templateId, docSignTemplate._id).subscribe((res) => {
          this.toastService.showSuccess(docSignTemplate.documents.length === 1 ? 'Document has been deleted successfully': 'Document' + ' has been deleted');
          this.getDocuSignTemplates();
        });
      }
    });
  }

  uploadDocument($event: Event) {
    let files = ($event.target as HTMLInputElement)?.files;
    if(files && files.length){
      for(let i = 0; i < files.length; i++){
        if(files[i] && this.isGreaterThanAllowedSize(files[i])){
          this.toastService.showError("Document size exceeding limit must be " + constants.documentUploadSizeInMbs + "mb or less.");
          return;
        }
      }
      const isFileExist = this.projectFiles.find((file: any) => {
        for(let i = 0; i < files.length; i++){
          if(file.name === files.item(i)?.name){
            return file;
          };
        }
        return null;
      });
      if(!isFileExist){
        for(let i = 0; i < files.length; i++){
          this.projectFiles.push(files.item(i));
        }
      }

      this.addDocumentsToList();
    }
  }

  addDocumentsToList(): void{
    this.docTitleRequired = false;
    this.docAdded = true;
    const files = this.projectFiles;       
    files.forEach((d:any, i:number)=>{
      const documentExists = this.templateDocuments.find((doc: any) => doc.name === d.name);
      if(documentExists){
        return;
      }
      this.templateDocuments.push({file: d, docTitle: ``, name: d.name});
    })
  }

  onUploadDocumentsNext() {
    if (this.projectFiles && this.projectFiles.length) {
      if(!this.templateName){
        this.toastService.showError("Template name is missing");
        return;
      }
      for(const document of this.templateDocuments){
        if(!document.docTitle){
          this.toastService.showError("Doc title missing");
          return;
        }
      }
      if(this.selectedDocSignTemplate){
        this.permanentAddconfirmVisible = true;
      } else {
        this.onDocuSignEnableNext('');
        
      }
      this.hideDialog();
    }
  }

  uploadResumeDocument($event: Event) {
    const files = ($event.target as HTMLInputElement)?.files;
    if (files && files.length) {
      this.projectResumeFile = files[0];
      this.fileName = files[0].name;
      this.projectFiles = files;
    }
  }

  saveResumeDocumentAndNext(): any{
    if(this.resumeDocumentForm && !this.resumeDocumentForm.valid){
      return;
    }
    const fileOpts: any = {
      projectId: this.projectId,
      documentType: 'BLANK_FORM'
    };
    if (this.resumeDocumentForm.value.instructions) {
        fileOpts.userTitle = this.resumeDocumentForm.value.instructions;
    }
    if (this.resumeDocumentForm.value.docTitle) {
        fileOpts.docTitle = this.resumeDocumentForm.value.docTitle;
    }
    this.isSaveTemplateInProgress = true;
    this.documentService.uploadResumeDocument(this.projectId || '', this.projectResumeFile, fileOpts).subscribe({
      next: (response) => {
        
        this.updateProjectState.emit();
        this.hideResumeDialog();
        this.isSaveTemplateInProgress = false;
      },
      error: (error: any) => {
        let errorMessage = error.data ? error.data.message: '';
        if (error.statusText && error.statusText === 'Request Entity Too Large') {
            errorMessage = "File is too large";
        }
        this.toastService.showError(errorMessage);
        this.isSaveTemplateInProgress = false;
      },
    });
  }

  async updateResumeDocumentAndNext(): Promise<void>{
    if(this.resumeDocumentForm && !this.resumeDocumentForm.valid){
      return;
    }

    const docInfo: {userTitle: string, docTitle: string} = {
      userTitle: '',
      docTitle: ''

    }

    if (this.resumeDocumentForm.value.instructions) {
      docInfo.userTitle = this.resumeDocumentForm.value.instructions;
    }

    if (this.resumeDocumentForm.value.docTitle) {
      docInfo.docTitle = this.resumeDocumentForm.value.docTitle;
    }

    this.isSaveTemplateInProgress = true;

    await this.documentService.updateResumeDocument(this.selectedResumeDocument?._id || '', docInfo);
    this.isSaveTemplateInProgress = false;
    this.selectedResumeDocument = null;
    this.visibleResumeDialog = false;
    this.resumeDocumentForm.reset();
    this.updateProjectState.emit();
  }

  

  updateInExistingTemplate(response: any){

    let document = response.file[0].file;
    this.pdfDocuments = this.pdfDocuments.concat(response.file);

    let fileOptions = this.pdfDocuments.map((doc: any) => {
      return {
        projectId: this.projectId,
        name: document.name,
        docTitle: doc.docTitle
      };
    });

    this.hideDialog();
    this.isSaveTemplateInProgress = true;
    this.projectService.updateDocuSignTemplate(document, fileOptions, this.selectedDocSignTemplate.name, this.selectedDocSignTemplate.templateId, this.selectedDocSignTemplate.documents.length)
      .subscribe(
        {
          next: (res) => {
            this.getDocuSignTemplates();
            this.addToTemplate = true;
            this.toastService.showSuccess("Document added successfully.");
            this.projectFiles = [];
            this.docTitle = '';
            this.templateDocuments = [];
            this.pdfDocuments = [];
            this.hideDialog();
            this.selectTemplateType = 'Existing';
            this.reloadTableData();
            this.selectedDocSignTemplate = null;
            const documentName = res.documents && res.documents.length ? res.documents[res.documents.length - 1].name || '' : '';
            this.editTemplateDocumentView(res.templateId, res.documents.length, documentName, res);
            this.isSaveTemplateInProgress = false;
          },
          error: (error) => {
            this.onFailureUpsertingTemplate(error);
            this.isSaveTemplateInProgress = false;
          }
        }
      );
  }

  getTemplateFieldsOrDefault(templateFileds: any) {
    if(Object.keys(templateFileds).length === 0) {
      let obj = {
        signHere:[],
        dateSigned:[],
        textTab_fullName:[],
        textTab_email:[],
        textTab_custom:[],
        checkBoxTab:[],
        initialHere:[],
        radioTab:[],
        signerAttachment:[]
      };
      return obj;
    }
    return templateFileds;
  }


  isGreaterThanAllowedSize(file: any){
    return (file.size > constants.documentUploadSizeInMbs * 1000000);
  }

  addNewTemplate(): void{
    this.selectedDocSignTemplate = null
    this.showDialog();
  }

  showDialog() {
    if(this.selectedDocSignTemplate){
      this.templateName = this.selectedDocSignTemplate.name;
    }
    this.selectTemplateType = 'New';
  }

  back(){
    this.selectTemplateType = 'Existing';
    this.projectFiles = [];
    this.docTitle = '';
    this.templateName = '';
    this.templateDocuments = [];
  }

  hideDialog(){
    this.visible = false;
  }

  showResumeDialog() {
    this.isEditResumeDocument = false;
    this.visibleResumeDialog = true;
  }

  showEditResumeDialog(documents: Documents){
    this.selectedResumeDocument = documents;
    this.resumeDocumentForm.controls['docTitle'].setValue(this.selectedResumeDocument.docTitle);
    this.resumeDocumentForm.controls['instructions'].setValue(this.selectedResumeDocument.title);
    this.isEditResumeDocument = true;
    this.visibleResumeDialog = true;
  }

  hideResumeDialog(){
    this.visibleResumeDialog = false;
    this.resumeDocumentForm.reset();
    this.fileName = 'No file selected';
  }

  async saveAndLogout(): Promise<void>{
    const currentProjectSteo = Number(this.project?.step || 0);
    if (currentProjectSteo < 5) {
      const pId = this.projectId || '';
      this.projectNavigation.setCurrentStep(4);
      await this.projectService.updateProjectStep(pId, 4);
      this.goToProjectListing();
    } else {
        this.projectNavigation.setCurrentStep(currentProjectSteo);
        this.goToProjectListing();
    }
  }

  goToProjectListing(): void {
    switch (this.project?.status) {
        case constants.projectStatus.created:
            this.router.navigate([`/client/project/unpublished`]);
            break;
        case constants.projectStatus.published:
            this.router.navigate([`/client/project/published`]);
            break;
        case constants.projectStatus.started:
            this.router.navigate([`/client/project/started`]);
            break;
        case constants.projectStatus.archived:
            this.router.navigate([`/client/project/archived`]);
            break;
        default:
            this.router.navigate([`/client/overview`]);
    }
  }

  onRowExpand(event: TableRowExpandEvent) {}
  onRowCollapse(event: TableRowCollapseEvent) {}
}
