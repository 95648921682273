
<form [formGroup]="infoForm">
    <div>
        <p>Type a short message explaining any additional information you’d like <br> to see in this candidate's profile prior to approval. </p>
    </div>
      <div class="grid mt-2 mb-3">
        <div class="col-12">
          <p-floatLabel>
            <textarea [(ngModel)]="text" rows="4" formControlName="information" pInputTextarea maxlength="200"></textarea>
            <p class="m-0 flex justify-content-end text-gray-600">{{text.length}}/200</p>
            <label for="information">Information<span class="required">*</span></label>
          </p-floatLabel>
        </div>
      </div>
      <div class="flex justify-content-end gap-3">
        <p-button
          label="Send"
          size="large"
          [disabled]="!infoForm.valid"
          [rounded]="true"
          styleClass="freshgreen-background"
          (click)="onSubmit()"
        />
        <p-button
          label="Cancel"
          size="large"
          [rounded]="true"
          (click)="closeDialog()"
        />
      </div>
</form>