<app-heading [title]="projectsTypeTitle" [button]="headingButton" [showToggle]="false"></app-heading>
<div class="projects-data">
    <div class="grid grid-nogutter">
        <div class="col-12 p-0">
            <div class="grid grid-nogutter">
                <div class="col-12 md:col-6 xl:col-4 lg:col-6 sm:col-12 p-0">
                    <div class="wrap-left">
                       <div class="grid grid-nogutter">
                            <div class="md:pr-1 sm:pr-0 col-12 md:col-6 sm:col-12 ">
                                <p-iconField iconPosition="right">
                                    <p-inputIcon styleClass="pi pi-search" />
                                    <input [(ngModel)]="searchText" (keydown)="onEnter($event)" type="text" pInputText placeholder="Search Projects" />
                                </p-iconField>
                            </div>
                            <div class="p-0 col-12 md:col-12 sm:col-12">
                                <ul class="chips">
                                    @for (searchLabel of searchedItems; track searchedItems;) {
                                        <li><p-chip label="{{searchLabel}}" [removable]="true" (onRemove)="clearProjectSearch()" /></li>
                                     }
                                </ul>
                            </div>
                       </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 xl:col-8 lg:col-6 sm:col-12 p-0 flex md:justify-content-end justify-content-center my-2 md:my-0">
                    <div class="wrap-right">
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <div class="p-datatable-striped">
                <p-table 
                #dt
                [value]="projects"
                styleClass="p-datatable-sm p-datatable-sm--normal"
                [tableStyle]="{ 'min-width': '50rem' }"
                [rows]="10"
                [paginator]="true"
                [totalRecords]="projectsCount"
                [lazy]="true"
                (onLazyLoad)="getProjects($event)"
                [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                autoLayout="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [pSortableColumn]="'codeName'" style="width:23%">
                                Name <p-sortIcon [field]="'codeName'" />
                            </th>
                            <th [pSortableColumn]="'client'" style="width:23%">
                                Clients <p-sortIcon [field]="'client'" />
                            </th>
                            @if(projectType != 'ARCHIVED' && projectType!= 'UNPUBLISHED'){
                                <th [pSortableColumn]="'publishedAt'" style="width:22%">
                                    Published Date <p-sortIcon [field]="'publishedAt'" />
                                </th>
                                <th style="width:22%">
                                    Filled Positions
                                </th>
                            }
                            @if(projectType === 'UNPUBLISHED'){
                                <th style="width:22%">
                                    Step
                                </th>
                            }
                            @if(projectType === 'ARCHIVED'){
                                <th [pSortableColumn]="'archivedAt'" style="width:22%">
                                    Ended Date <p-sortIcon [field]="'archivedAt'" />
                                </th>
                            }
                            <th>
                                Actions
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-project>
                        <tr class="table-data" (click)="goToProjectDetails(project)" style="cursor:pointer;">
                            <td>{{ project.codeName }}</td>
                            <td>{{ this.clientName(project.client) }}</td>
                            @if(projectType !== 'ARCHIVED' && projectType !== 'UNPUBLISHED'){
                            <td>{{ project.publishedAt | date: 'MM/dd/YYYY'}}</td>
                            <td>{{ project.positionsFilled }}/{{ project.positionsListed}}</td>
                            }
                            @if(projectType === 'UNPUBLISHED'){
                                <td>{{ project.step}}/7</td>
                          
                            }
                            @if(projectType === 'ARCHIVED'){
                                <td>{{ project.archivedAt | date: 'MM/dd/YYYY'}}</td>
                            
                            }
                            <td>
                                <div class="wrap-actions flex align-items-center gap-2">
                                    @if(projectType !== 'ARCHIVED'){
                                    <p-button pTooltip="Edit" 
                                    tooltipPosition="top"  [rounded]="true" [text]="true" icon="true" (click)="onEdit($event,project)">
                                        <span class="material-symbols-outlined">
                                            edit
                                        </span>
                                    </p-button>
                                   
                                    <p-button pTooltip="Archive" 
                                    tooltipPosition="top"  (click)="showArchiveConfirmation($event,project)" [rounded]="true" [text]="true" icon="true">
                                        <span class="red material-symbols-outlined">
                                            archive
                                        </span>
                                    </p-button>
                                    }
                                    @if(projectType === 'ARCHIVED' && !project.isDeleted){
                                        <p-button pTooltip="Restore project" 
                                        tooltipPosition="top" (click)="projectRestore($event,project)" [rounded]="true" [text]="true" icon="true">
                                            <span class="material-symbols-outlined p-4">
                                                sync
                                            </span>
                                        </p-button>
                                    
                                    }
                                   
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
<!-- <app-rate-freelancers/> -->


