@if(!isDataReady){
    <p-skeleton styleClass="mb-2" />
    <p-skeleton styleClass="mb-2" />

    <p-skeleton width="75%" styleClass="mb-2"/>
    <p-skeleton width="75%" styleClass="mb-2"/>

    <p-skeleton width="10rem" styleClass="mb-2" />
    <p-skeleton width="10rem" styleClass="mb-2" />

    <p-skeleton width="5rem" styleClass="mb-2" />
    <p-skeleton width="5rem" styleClass="mb-2" />    
}
@else{

    <form [formGroup]="projectInfoForm" class="grid grid-nogutter" (ngSubmit)="onSubmit()">
            
        <div class="p-0 mb-3 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-2 lg:pl-2 pr-2">
            <p-floatLabel>
                <input pInputText id="username" formControlName="codeName" />
                <label for="username">Project Code Name<span class="required">*</span></label>
            </p-floatLabel>
            <div>
                @if(form['codeName'].dirty || form['codeName'].touched){
                    @if(form['codeName'].errors?.['required']){
                        <div class="p-error ">This is required.</div>
                    }
                    @if(form['codeName'].errors?.['pattern']){
                        <div class="p-error">Only 100 characters of Alphanumeric, Hyphens(-) and Spaces are allowed.</div>
                    }
                }
            </div>
        </div>
        
        <div class="p-0 mb-3 col-12 xl:col-6 lg:col-6 pr-0 xl:pr-2 lg:pr-2 pl-2">   
            <p-floatLabel>
                <input pInputText id="username" formControlName="client" />
                <label for="username">Client Name</label>
            </p-floatLabel>
        </div>
    
        <div class="p-0 mb-3 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-2 lg:pl-2 pr-2">
            <p-floatLabel>
                <p-dropdown 
                    [options]="projectOwners"
                    optionLabel="name" 
                    formControlName="owner"
                    inputId="float-label" />
                <label for="float-label">Project Owner</label>
            </p-floatLabel>
        </div>
        <div class="p-0 mb-3 col-12 xl:col-6 lg:col-6 pr-0 xl:pr-2 lg:pr-2 flex flex-column gap-3 pl-2">
            @if(user.firm.isCommunicationPlatformEnabled){
                <div class="w-full">
                    <p-floatLabel>
                        <p-dropdown 
                            [options]="communicationOwners"
                            optionLabel="name" 
                            inputId="float-label" 
                            formControlName="selectedCommunicationPlatforms"/>
                        <label for="float-label">Communication Owner</label>
                    </p-floatLabel>
                </div>
            }
            @if(user.firm.isCommunicationPlatformEnabled && getFormValuesByProperty('communicationPlatform')?.platformId){
                <div class="w-full">
                    <p-floatLabel>
                        <input pInputText id="username" formControlName="communicationPlatformUrl" />
                        <label for="float-label">Communication Platform URL<span class="required">*</span></label>
                    </p-floatLabel>
                    @if((form['communicationPlatformUrl'].dirty || form['communicationPlatformUrl'].touched) && !getFormValuesByProperty('communicationPlatformUrl')){
                        <div class="p-error">This is required.</div>
                    }
                    @if((form['communicationPlatformUrl'].dirty || form['communicationPlatformUrl'].touched) && getFormValuesByProperty('communicationPlatformUrl') && !isValidURL(getFormValuesByProperty('communicationPlatformUrl'))){
                        <div class="p-error">Invalid URL.</div>
                    }
                </div>
            }
        </div>
        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-2 lg:pl-2 flex flex-column gap-3 pr-2">
            @if(user.firm.isCommunicationPlatformEnabled){
                <div class="w-full">
                    <p-floatLabel>
                        <p-dropdown 
                            [options]="reviewerPlatforms"
                            optionLabel="name" 
                            inputId="float-label" 
                            formControlName="selectedReviewerPlatform"/>
                        <label for="float-label">Review Platform</label>
                    </p-floatLabel>
                </div>
            }
            @if(user.firm.isCommunicationPlatformEnabled && getFormValuesByProperty('reviewerPlatform')?.platformId){
                <div class="w-full">
                    <p-floatLabel>
                        <input pInputText id="username" formControlName="reviewerPlatformUrl" />
                        <label for="float-label">Review Platform URL<span class="required">*</span></label>
                    </p-floatLabel>
                    @if((form['reviewerPlatformUrl'].dirty || form['reviewerPlatformUrl'].touched) && !getFormValuesByProperty('reviewerPlatformUrl')){
                        <div class="p-error">This is required.</div>
                    }
                    @if((form['reviewerPlatformUrl'].dirty || form['reviewerPlatformUrl'].touched) && getFormValuesByProperty('reviewerPlatformUrl') && !isValidURL(getFormValuesByProperty('reviewerPlatformUrl'))){
                        <div class="p-error">Invalid URL.</div>
                    }
                </div>
            }
        </div>
        
        @if(user?.firm?.isInvoicingEnabled){
            <div class="p-0 mb-4 col-12 flex flex-row gap-3 pl-2" >
                <p class=" text-black-alpha-90 m-0">Enable Contractor Timekeeping?</p>
                <label>No</label> 
                <p-inputSwitch formControlName="contractorTimekeepingEnabled"/>
                <label>Yes</label>
            </div>
        }
        <div *ngIf="user.firm.isPresumptiveEnabled" class="p-0 mb-4 col-12 flex flex-column xl:flex-row gap-3 xl:gap-6 pl-2">
            <p class=" text-black-alpha-90 m-0">Do you want to enable Presumptively Assigned flow or Traditional flow?</p>
            <div class="flex align-items-center">
                <p-radioButton 
                    [value]="false" 
                    formControlName="isPresumptiveEnabled" 
                    inputId="traditional" />
                <label for="traditional" class="ml-2">
                    Traditional flow
                </label>
            </div>
            <div class="flex align-items-center">
                <p-radioButton 
                    [value]="true" 
                    formControlName="isPresumptiveEnabled" 
                    inputId="presumptively" />
                <label for="presumptively" class="ml-2">
                    Presumptively Assigned flow
                </label>
            </div>
        </div>
        @if(user?.firm?.payroll?.enabled){
            <div class="p-0 mb-4 col-12 flex flex-row gap-3 pl-2" formGroupName="tsheet">
                <p class=" text-black-alpha-90 m-0">Enable Timekeeping?</p>
                <label>No</label> 
                <p-inputSwitch formControlName="isEnabled" />
                <label>Yes</label>
            </div>
        }
        <div *ngIf="user.firm.isBackgroundCheckRequired" class="p-0 mb-4 col-12 flex flex-row gap-3 pl-2">
            <p class=" text-black-alpha-90 m-0">Run criminal background check?</p>
            <label>No</label> 
            <p-inputSwitch formControlName="backgroundCheckRequired" />
            <label>Yes</label>
        </div>
        <div *ngIf="user.firm.isCivilLitigationEnabled" class="p-0 mb-4 col-12 flex flex-row gap-3 pl-2">
            <p class=" text-black-alpha-90 m-0">Run civil litigation screening?</p>
            <label>No</label> 
            <p-inputSwitch formControlName="civilLitigationScreeningRequired"/>
            <label>Yes</label>
        </div>

        <div class="col-12 p-0 flex justify-content-end gap-3">
            <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
            <p-button styleClass="freshgreen-background" type="submit" label="Next" [rounded]="true"  size="large"/>
        </div>
    </form>
}