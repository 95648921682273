<app-heading [title]="contractorTimekeepingEnabled ? 'Invoice Generator' : 'Timekeeping'" [button]="false" [isbreadcrumb]="false" [showToggle]="false"></app-heading>
<div class="flex flex-column gap-4">
    <div class="lg:w-28rem w-full">
        <p-dropdown inputId="role" [(ngModel)]="selectedRole" (onChange)="onRoleChange($event)" [options]="roles"
            optionLabel="name" placeholder="Select Role" />
    </div>
    <div class="grid">
        <div class="col-12 xl:col-6">
            <p-card role="region" styleClass="nogutter-content">
                <div class="flex flex-column gap-4">
                    <div class="flex justify-content-between align-items-center">
                        <span class="h6">Time Tracked Today</span>
                        <div class="text-black-alpha-60">
                            <span class="text-sm">Date: </span>
                            <span class="text-xs font-medium">{{todayDate | date:'MM-dd-yyyy'}}</span>
                        </div>
                    </div>
                    <div class="flex justify-content-between flex-column md:flex-row md:gap-0 gap-4">
                        <span
                            class="h3">{{dailyDuration?.hours}}:{{dailyDuration?.minutes}}:{{dailyDuration?.seconds}}</span>
                        <div class="flex gap-2 align-items-center">
                            @if(!isClockedIn && !hideButtons){
                            <p-button (click)="clockIn()" styleClass="teal white-space-nowrap"  label="Start time" rounded="true" size="large"
                                ></p-button>
                            }
                            @if(isClockedIn){
                                <p-button (click)="clockOut()" label="Stop time" rounded="true" size="large"
                                    styleClass="red-background white-space-nowrap"></p-button>
                                }
                            <p-button (click)="openAddTimeModal()" label="Add time" rounded="true" size="large"
                                styleClass="white-space-nowrap" outlined="true"></p-button>
                            <p-dialog header="Timesheet Editor" [modal]="true" [(visible)]="showTimeSheetEditor"
                                [draggable]="false" styleClass="md:w-6 w-full">
                                @if(showTimeSheetEditor){
                                <app-timesheet-editor (closeModal)="closeModal($event)" [data]="selectedTimeSheet"
                                    [isEdit]="isTimeSheetEditable" [roles]="roles"
                                    [selectedRole]="selectedRole"></app-timesheet-editor>
                                }
                            </p-dialog>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
        <div class="col-12 xl:col">
            <p-card role="region" styleClass="nogutter-content">
                <div class="flex flex-column gap-4">
                    <span class="h6">Week Total</span>
                    <span class="h3">{{weekDuration?.hours}}:{{weekDuration?.minutes}}</span>

                </div>
            </p-card>
        </div>
        <div class="col-12 xl:col">
            <p-card role="region" styleClass="nogutter-content">
                <div class="flex flex-column gap-4">
                    <span class="h6">Project Total</span>
                    <span class="h3">{{totalDuration?.hours}}:{{totalDuration?.minutes}}</span>
                </div>
            </p-card>
        </div>
    </div>
    <div class="flex justify-content-between md:align-items-center">
        <span class="h6">Time Tracked Today</span>
        <p-button (click)="getTsheetList(this.navigatedWeek)" label="Refresh" rounded="true" size="large"
            icon="pi pi-sync" styleClass="white-space-nowrap" outlined="true"></p-button>
    </div>
    <div class="flex flex-column xl:flex-row justify-content-between xl:align-items-center gap-4 xl:gap-0">
        <div class="flex gap-4 align-items-center justify-content-between">
            <span class="white-space-nowrap text-primary font-medium">This Week</span>
            <div class="flex align-items-center gap-2 justify-content-between">
                <p-button (click)="getPreviousWeekList()" type="button" pRipple [text]="true" [rounded]="true"
                    [plain]="true" icon="pi pi-chevron-left" />
                <span class="white-space-nowrap">
                    {{navigatedWeek.startDate.format('MM/DD/YYYY')}} - {{navigatedWeek.endDate.format('MM/DD/YYYY')}}
                </span>
                @if(ifWeekLimitNotReach()){
                <p-button (click)="getNextWeekList()" type="button" pRipple [text]="true" [rounded]="true"
                    [plain]="true" icon="pi pi-chevron-right" />
                }
            </div>





            <!-- <p-floatLabel class="lg:w-14rem w-full">
                <p-dropdown [options]="dummy" optionLabel="name" inputId="selectRole" placeholder="Select Role" />
                <label for="selectRole">Select Role</label>
            </p-floatLabel> -->
        </div>
        <div class="flex gap-2 align-items-center">
            @if(displayApproveTime){
                <p-button (click)="approveTime($event)" label="Approve Time" rounded="true" size="large"
                    styleClass="teal white-space-nowrap"></p-button>
            }
            @if(contractorTimekeepingEnabled){
                <p-button (click)="submitInvoice($event)" label="Submit Invoice" rounded="true" size="large"
                    styleClass="teal white-space-nowrap"></p-button>
                <p-button label="View Invoice" rounded="true" size="large" styleClass="white-space-nowrap" outlined="true"
                    (click)="showDialog()"></p-button>
            }
            <p-dialog header="Invoices" [modal]="true" [(visible)]="visible" [draggable]="false"
                styleClass="md:w-6 w-full">
                <span class="flex gap-4 text-sm font-medium mb-3">
                    <span>Role : {{selectedRole.name}} </span>
                    <span>Week: {{navigatedWeek.startDate.format('MM/DD/YYYY')}} - {{navigatedWeek.endDate.format('MM/DD/YYYY')}}</span>
                </span>
                <div class="flex align-items-center gap-3 mb-3">
                    <p-table [value]="invoices" dataKey="id" styleClass="p-datatable-sm p-datatable-sm--normal"
                        class="w-full" [rows]="7">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>S.No</th>
                                <th>Submitted On</th>
                                <th class="w-10rem">Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-invoice let-rowIndex="rowIndex">
                            <tr>
                                <td>{{rowIndex}}</td>
                                <td>{{invoice.createdAt | date: "MM/dd/yyyy hh:mm:ss a"}}</td>
                                <td>
                                    <div class="wrap-actions">
                                            <p-button (click)="getInvoiceUrl(invoice._id.toString())" [rounded]="true" [text]="true" icon="true"
                                                styleClass="text-primary-900">
                                                <span class="material-symbols-outlined"> download </span>
                                            </p-button>
                                       
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-dialog>
        </div>
    </div>
    <div>
        <p-table [value]="tsheets" dataKey="_id" styleClass="p-datatable-sm p-datatable-sm--normal" [rows]="7"
            [tableStyle]="{ 'min-width': '50rem' }">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="day">Day <p-sortIcon field="day" /></th>
                    <th pSortableColumn="timeIn">Time In <p-sortIcon field="timeIn" /></th>
                    <th pSortableColumn="timeOut">Time Out <p-sortIcon field="timeOut" /></th>
                    <th pSortableColumn="hours">Hours <p-sortIcon field="hours" /></th>
                    <th pSortableColumn="roles">Roles <p-sortIcon field="roles" /></th>
                    <th pSortableColumn="notes">Notes <p-sortIcon field="notes" /></th>
                    <th>Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tsheet let-rowIndex="rowIndex">
                <tr>
                    <td>{{day(tsheet.start)}}</td>
                    <td>{{dateTime(tsheet.start)}} {{timezone}}</td>
                    <td>{{tsheet.onTheClock ? 'working' : dateTime(tsheet.end)+' '+timezone}}</td>
                    <td> {{secondsToDecimal(tsheet.duration) || 0}}</td>
                    <td> {{tsheet.role.roleType}}</td>
                    <td>{{slimNotes(tsheet.notes)}}</td>
                    <td>
                        <div class="wrap-actions">
                            <p-button (click)="editTimeSheet(tsheet)" [rounded]="true" [text]="true" icon="true"
                                styleClass="text-primary-900">
                                <span class="material-symbols-outlined">
                                    edit
                                </span>
                            </p-button>
                            <p-button styleClass="red-icon" (click)="deleteInvoice($event,tsheet, rowIndex)" [rounded]="true" [text]="true"
                                icon="true">
                                <span class="material-symbols-outlined"> delete </span>
                            </p-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>