<div header="Delete User" class="align-items-center">
    <h5 class="block mb-5">{{config.data.heading}} </h5>
   
    <div class="col-12 p-0 mb-1">
        <p class="m-0 text-sm text-black-alpha">{{config.data.textAreaHeading}} <span class="required">*</span></p>
    </div>
    <div class="flex align-items-center gap-3 mb-3">
        <textarea rows="3" [value]="endMessage" (input)="onValueChange($event)"  pInputText id="end-reason" class="flex-auto" autocomplete="off" ></textarea>
    </div>
   
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (click)="closeDialog()" />
        <p-button [disabled]="!endMessage" label="OK" (click)="confirm(true)" />
    </div>
</div>
