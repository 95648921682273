
import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { constants } from '../constants/constants';
import { AuthService } from '../services/auth.service';

export const AuthInterceptor: HttpInterceptorFn = (request, next) => {
    const toast = inject(ToastService);
    const auth = inject(AuthService);

    return next(request).pipe(
        catchError((error: HttpErrorResponse) => {
            if ([HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.status)) {
                if(auth.isAuthenticated()){
                    toast.showError("Session Timed Out!");
                    auth.logout();
                }
                else if (error && error.message && error.error.message && typeof error.error.message === 'string') {
                    toast.showError(error.error.message);
                } 
                else {
                    toast.showError(constants.internalServerError.defaultErrorMessage);
                }
            }
            else if (error.status === constants.unknown_error.status_code) {
				toast.showError(constants.unknown_error.message);
			} else if(error.status === HttpStatusCode.InternalServerError){
                let err = error.error || null;
                if(err){
                    try {
                        if(err.message && typeof err.message === 'string'){
                            toast.showError(err.message);
                        }
                        else if(err.message && Array.isArray(err.message) && err.message.length){
                            toast.showError(err.message[0]);
                        }
                        else{
                            err = JSON.parse(err);
                            toast.showError(err.message);    
                        }
                    } catch (error) {
                        toast.showError(constants.internalServerError.defaultErrorMessage);
                    }
                } else {
                    toast.showError(constants.internalServerError.defaultErrorMessage)
                }
            }
            else if(!request.url.includes(constants.url.pastProject)){
                toast.showError(error.error.message);
            }
            return throwError(() => error);
        }),
    );
}; 
