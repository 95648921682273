import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpBaseService) { }

  getNotifications(isRead = false) {
    return lastValueFrom(this.http.get(`notifications?isRead=${isRead}&noCache=${new Date().getTime()}`));
  }

  markAsRead(id: string) {
    return lastValueFrom(this.http.put(`notifications/${id}/markAsRead`));
  }
}
