import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '../../../../shared/services/toast.service';
import { MessagesService } from '../../../../shared/services/messages-service.service';
import { MessageCreateDialogData, ProjectItem, ReviewerItem, RoleItem } from './message.interface';
import { constants } from '../../../../shared/constants/constants';
import { CreateDiscussionComponent } from './modals/create-discussion/create-discussion.component';
import { FirebaseMessagingService } from '../../../../shared/services/firebase-messaging.service';
import { map } from 'rxjs/operators';
import { Reviewer } from '../people/people.interface';
import { MessageComponent } from './message.component';

@Injectable({
  providedIn: 'root'
})
export class MessageComponentService {
  ref: DynamicDialogRef | undefined;
  viewDiscussionData: any={};

  constructor(  private dialogService: DialogService,
    private toast: ToastService,
    private messagesService: MessagesService,
    private firebaseMessagingService: FirebaseMessagingService) { }

  generateDialogData(node:any): MessageCreateDialogData{
    let nodeData:MessageCreateDialogData;
    switch(node.node_type){
      case 'PROJECT':{
        nodeData = {
          projectId: node.key,
          roleId: null,
          reviewerId: null,
          msgType: node.node_type,
          title: node.label,
          status: node.data.status,
          node: node
        };
        break;
      }
      case 'ROLE':{
        nodeData = {
          projectId: node.parent.key,
          roleId: node.key,
          reviewerId: null,
          msgType: node.node_type,
          title: node.label,
          status: node.data.status,
          node: node
        };
        break;
      }
      case 'REVIEWER':{
        nodeData = {
          projectId: node.data.projectId,
          roleId: node.data.roleId,
          reviewerId: node.data._id,
          msgType: node.node_type,
          title: node.label,
          status: node.data.status,
          node: node,
          formerlyAssigned: node.data.status !== constants.positionStatus.occupied
        };
        break;
      }
      default: {
        nodeData = {
          projectId: node.key,
          roleId: null,
          reviewerId: null,
          msgType: node.node_type,
          title: node.label,
          status: node.data.status,
          node: node
        };
        break;
      }
    }
    return nodeData;
  }
    generateDialogDataRole(node:any): MessageCreateDialogData{
    let nodeData:MessageCreateDialogData;
    
    nodeData = {
          projectId: node.parent.key,
          roleId: node.key,
          reviewerId: null,
          msgType: node.node_type,
          title: node.label,
          status: node.data.status,
          node: node
        };
    
    return nodeData;
  }

  generateDialogDataReviewer(node:any): MessageCreateDialogData{
    let nodeData:MessageCreateDialogData;
    
    nodeData = {
      projectId: node.projectId,
      roleId: node.roleId,
      reviewerId: node.reviewer._id,
      msgType: 'REVIEWER',
      title:`${node.reviewer.firstName} ${node.reviewer.lastName}`,
      status: node.status,
      node: node,
      formerlyAssigned: node.status !== constants.positionStatus.occupied
    };
    
    return nodeData;
  }
  openCreateDiscussion(node:{msgType:string,projectId:string,roleId:string,reviewerId:string,reviewer:ReviewerItem,status:string}): void {
      this.firebaseMessagingService.getFirmDiscussionsOnce(node.projectId,node.roleId,node.reviewerId)
      .pipe(
        map(res => {
          let firmDiscussionSnapshots = res ? {...res} : null;
      
          if (firmDiscussionSnapshots && Object.keys(firmDiscussionSnapshots).length > 0) {
            // got to messaging view and open selected discussions
            this.setupViewData(node);
            this.openMessageDiscussionsViewDialog();
          } else {
            this.openNewMessageDialog(this.generateDialogDataReviewer(node));
          }
        })
      )
      .subscribe({
        error: () => {
          this.openNewMessageDialog(this.generateDialogDataReviewer(node));
        }
      });     
  }

  setupViewData(reviewerNodeData:any){
    this.viewDiscussionData = reviewerNodeData;
  }

  hasPendingViewData(){
    return Object.keys(this.viewDiscussionData).length > 0;
  }

  getPendingViewData(){
    return this.viewDiscussionData;
  }

  clearPendingViewData(){
    this.viewDiscussionData = {};
  }


  generateProjectNode(project:ProjectItem,index:number){
    let roles:any[] = [];
    Object.values(project.roles).forEach((role)=>{
      roles.push(this.generateRoleNode(role,project));
    })
    return {
      key: project._id,
      label: project.codeName,
      data: project,
      children: roles,
      node_type:'PROJECT'
    }
  }

  generateRoleNode(role:RoleItem,project:ProjectItem){
    let reviewers:any[] = [];
    Object.values(role.reviewers).forEach((reviewer)=>{
      reviewers.push(this.generateReviewerNode(reviewer,project._id,role._id));
    })
    return {
      key: role._id,
      label: role.roleType,
      data: role,
      projectId: project._id,
      children: reviewers,
      node_type:'ROLE'
    }
  }
  generateReviewerNode(reviewer:ReviewerItem,projectId:string,roleId:string){
    return {
      key: reviewer._id,
      label: `${reviewer.firstName} ${reviewer.lastName}`,
      data: {...reviewer,projectId,roleId},
      icon: 'person',
      status:reviewer.status,
      node_type:'REVIEWER'
    }
  }
  
  openNewMessageDialog(data:MessageCreateDialogData){
    this.ref = this.dialogService.open(CreateDiscussionComponent, {
      data: data,
      header: 'Group Message',
      width: '660px',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '575px': '90vw' 
      }
    });
    this.ref.onClose.subscribe((message: any) => {
      if (message === 'success') {   
          this.toast.showSuccess('Message Sent');    
      }
      });
  }
  openMessageDiscussionsViewDialog(){
    this.ref = this.dialogService.open(MessageComponent, {
      data:{},
      header: 'Discussions',
      width: '750px',
      contentStyle: { 'overflow-y': 'scroll' },
      breakpoints: {
        '575px': '90vw' 
      }
    });
    this.ref.onClose.subscribe((message: any) => {
      // if (message === 'success') {   
      //     this.toast.showSuccess('Discussion Created Successfully');    
      // }
      });
  }

}
