import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { constants } from '../../../../../../../shared/constants/constants';
import { ToastService } from '../../../../../../../shared/services/toast.service';
@Component({
    providers: [MessageService],
    standalone:true,
    imports:[ButtonModule,DynamicDialogModule,RadioButtonModule, FormsModule],
    templateUrl: './delete-user-confirmation.component.html'
})
export class DeleteUserConfirmation  {
    endMessage:string='';
    visible: boolean = false;
    reason: string = 'INACTIVITY';
    deleteReasons  = constants.userDeleteReasons;
    constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig, private toast: ToastService) {}

    onValueChange($event:Event){
        this.endMessage = ($event.target as any).value;
    }

    closeDialog(){
        this.ref.close();
    }

    confirm(isConfirm: boolean = false) {
        if(!isConfirm){
            return this.ref.close();
        }
        if(!this.endMessage){
            return this.toast.showError("Please select Reason.")
        }
        this.ref.close({comments: this.endMessage, reason: this.reason});
    }

}
