<div class="align-items-center send-mail-wrapper">
    <div class="grid p-0">
        <div class="inline-flex align-items-center justify-content-center gap-2 pb-3">
            <span class="white-space-nowrap">
                To: <span class="font-bold">{{emailInfo?.recipient}}</span>
            </span>
        </div>
        <div class="col-12 px-0 pb-3">
            <p-floatLabel>
                <p-multiSelect 
                    inputId="float-label" 
                    [options]="emailInfo?.projectOwners" 
                    [(ngModel)]="selectedProjectOwners" 
                    optionLabel="email" />
                <label for="float-label">Reply To Address</label>
            </p-floatLabel>
            <div>
                @if(!selectedProjectOwners.length){
                    <div class="p-error ">Invalid email address.</div>
                }
            </div>
        </div>
        <div class="col-12 px-0 pb-3">
            <p-floatLabel>
                <p-chips id="ccEmail" [(ngModel)]="ccEmails" styleClass="flex w-full" addOnBlur="true" />
                <label for="ccEmail">CC to Address</label>
            </p-floatLabel>
            <div class="grid grid-nogutter">
                    <div class="col-12 lg:col-11 xl:col-11">
                        @if(!validateEmailAddress(0)){
                            <span class="p-error pl-1 text-xs block">Invalid email address</span>
                        }
                    </div>
            </div>
        </div>
        <div class="col-12 px-0 pb-3">
            <p-floatLabel>
                <input (blur)="onSubjectChange()" class="p-inputtext p-component" id="username" type="text" pInputText [(ngModel)]="subject" width="100%" />
                <label for="username">Subject</label>
            </p-floatLabel>
            @if(isSubjectChanged && !subject){
                <div class="p-error ">This is required.</div>
            }
        </div>
        <div class="col-12 px-0 py-0">
            <p-floatLabel>
                <textarea 
                    id="float-input" 
                    rows="5" cols="30"
                    class="p-inputtextarea p-inputtext p-component p-element"
                    placeholder="Write Message..."
                    [(ngModel)]="emailContent"
                    pInputTextarea>
                </textarea>
            </p-floatLabel>
            
        </div>
        <div class="grid grid-nogutter">
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                <p-button (click)="sendMailFileInput.click()" [label]="fileLabel" icon="pi pi-plus" size="large"
                [rounded]="true" />
                <input #sendMailFileInput id="sendMailFileInput" type="file" class="hidden" value="..." name="file" accept="image/*,text/plain,application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.doc,.docx,.xls,.xlsx,.txt" button-disable
                (change)="onUploadChange($event)" multiple>
            </div>
            @if(attachment.sizeLimitExceeded){
                <div class="grid grid-nogutter">
                    <div class="col-12 lg:col-11 xl:col-11">
                            <span class="p-error pl-1 text-xs block">Your files are larger than 25MB. Please unselect some files.</span>
                    </div>
                </div>
            }
        </div>
        
        
        @if(this.attachment.files && this.attachment.files.length){
            <div class="col-12 px-0 py-0">
                <ul>
                    @for (fileObject of this.attachment.files; track fileObject.name) {
                    <li style="text-align: left;">
                        <label class=" mb-0 w-100 " style="display: inline;">{{ fileObject.name }}</label>
                        <i class="icon-file" style="font-size: 18px; display: inline;"></i>
                    </li>
                    }
                </ul>
            </div>
        }
    </div>
    <div class="flex justify-content-end gap-2">
        <p-button [disabled]="!this.validateSubmission()" label="Send Email" size="large" [rounded]="true" (onClick)="onSubmit()"/>
    </div>
</div>
