import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ResponseTabComponent } from './response-tab/response-tab.component'
import { DetailsTabComponent } from './details-tab/details-tab.component';
import { DocumentService } from '../../../../../shared/services/document-service';
import { StaffTabComponent } from './staff-tab/staff-tab.component';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentEditComponent } from './modals/document-edit/document-edit.component';
import { CompanyService } from '../../../../../shared/services/company.service';
import { HelperService } from '../../../../../shared/services/helper.service';
import { EmittersService } from '../../../../../shared/services/emitters.service';
import { constants } from '../../../../../shared/constants/constants';
@Component({
  selector: 'app-details',
  standalone: true,
  imports: [MultiSelectModule, FloatLabelModule, DialogModule, CheckboxModule, TabViewModule, DropdownModule, ButtonModule, 
    FormsModule, ChipModule, IconFieldModule, InputIconModule, HeadingComponent, InputTextModule, TableModule, CommonModule, 
    StaffTabComponent, ResponseTabComponent, DetailsTabComponent, TooltipModule],
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig],

})
export class DetailsComponent implements OnInit {

  products: any;
  checked: boolean = false;
  expandedRows = {};
  visible: boolean = false;
  headingButton = {
    url: '',
    label: 'EDIT PROJECT',
    icon: 'pi pi-pencil'
  }
  isLoading = true;
  projectId!: string | null;
  projectDetails! : any;
  projectStaffList: any;
  projectPositionsListed: number = 0;
  projectPositionsFilled: number = 0;
  companies: any = [];
  ref: DynamicDialogRef | undefined;
  maxLength: number = 70;
  pdfSource: {url: string} | null = null;
  status = constants.projectStatus;

  constructor(private route: ActivatedRoute,
    private projectService: ProjectService,
    private documentService: DocumentService,
    public dialogService: DialogService,
    private companyService: CompanyService,
    public helperService: HelperService,
    private emitterService: EmittersService
  ) {
    this.emitterService.staffTabEmitter.subscribe(()=>{
      this.ngOnInit();
    })
  }

  showDialog() {
    this.visible = true;
  }

  async ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    this.headingButton.url = `/client/project/${this.projectId}/edit`;
    this.getProjectStaffList();
    this.getProjectDetails();
    this.companies = await this.companyService.getCompanies();
  }

  getProjectDetails(): void {
    if (this.projectId){
      this.projectService.getProjectById(this.projectId).subscribe({
        next: (resp) => {
          if(resp.documents.length > 0){
            resp.documents.forEach((element: { url: string | null; _id: string; docTitle: string, userTitle: string}) => {
              element.url = this.documentService.getDocumentPathById(element._id);
              element.docTitle = element.docTitle && element.docTitle.length > this.maxLength ? element.docTitle.slice(0, this.maxLength) + '...' : element.docTitle;
              element.userTitle = element.userTitle && element.userTitle?.length > this.maxLength? element.userTitle.slice(0, this.maxLength) + '...' : element.userTitle;
            });
          }
          this.projectDetails = resp;
        }
      })
    }
  }

  getProjectStaffList(): void {
    this.projectStaffList = {};
    if (this.projectId)
      this.projectService.getProjectStaffList(this.projectId).subscribe({
        next: (resp) => {
          this.projectPositionsListed = resp.positionsListed;
          this.projectPositionsFilled = resp.positionsFilled;
         
          resp.allStaff.map((role: any) => {
            role.currentlyAssigned = [];
            role.formerlyAssigned = [];
            let sortedUniqueList:any = [];
            role.positionsList.map((position: any) => {
              if (position.status !== 'QUIT' && position.status !== 'FIRED' && position.status !== 'COMPLETED') {
                role.currentlyAssigned.push(position);
              }
              else {
                role.formerlyAssigned.push(position);
              }
            });

            if (role.positionsList && role.positionsList.length) {
              let grouped = this.groupByNestedKeys(role.positionsList,['reviewer._id','status','presumptive.status']);
              Object.keys(grouped).forEach((category)=>{
                if(grouped[category].length){
                  let sorted = grouped[category].sort((a:any, b:any) => new Date(a.date).getTime() - new Date(b.date).getTime());
                  sortedUniqueList.push(sorted[sorted.length-1]);

                }
              })
              role.positionsList = sortedUniqueList; 
             
            }
          });
          this.projectStaffList = resp.allStaff;
          this.isLoading = false;
        }
      })
  }
  
  getValueByPath (obj:any, path:any){
    return path.split('.').reduce((acc:any, key:any) => acc && acc[key], obj);
  }
  
  groupByNestedKeys (array:any, keys:any) {
    return array.reduce((result: any, item: any) => {
      const groupKey = keys.map((key: any) => this.getValueByPath(item, key)).join('|');
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(item);
      return result;
    }, {});
  }

  downloadDocument(document:any){
    window.open(document.url, '_blank')
  }

  getModalConfig(data?: any) {
    return {
      header: 'DOCUMENTS',
      width: '30vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: { files: data, projectId: this.projectId}
    };
  }

  openDocumentEditModal($event: Event, documents: any[]){
    $event.stopPropagation();
    this.ref = this.dialogService.open(
      DocumentEditComponent,
      this.getModalConfig(documents)
    );

    this.ref.onClose.subscribe((data) => {
        this.getProjectDetails();
    });

  }

  onRowExpand(event: TableRowExpandEvent) { }

  onRowCollapse(event: TableRowCollapseEvent) { }

  updateAndReInitializeStaff(): void{
    this.getProjectStaffList();
    this.getProjectDetails();
  }

  captionName(caption: any): string {
    return this.companies.filter((company: any) => company._id === caption)[0]?.name || caption
  }

  viewDocument(templateId: string, documentId: string, documentName: string): void{
    this.documentService.getDocuSignDocument(templateId, documentId).subscribe({
      next: async (res) => {
        const url = URL.createObjectURL(new Blob([res]));
        const response = await fetch(url);
        const blob = await response.blob();
        const pdfContent = await this.documentService.getFileReaderObject(blob);

        const uri = `data:application/octet-stream,${window.escape(pdfContent)}` ;
        this.pdfSource = {url: uri};
        let downloadLink = document.createElement("a");
        downloadLink.href = uri;
        downloadLink.download = documentName.indexOf(".pdf")>-1?documentName:documentName+".pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    });
  }
}
